// todo: move to validation.js, rename is4DigitYear?
export function isYear(inputString) {
    var regEx = /^\d{4}$/;

    if (!inputString.match(regEx)) {
        return false;
    }

    // no dates in the future
    return (inputString <= (new Date()).getFullYear());
}


// todo: move to validation.js
export function isUri(str) {
    // from https://github.com/jquery-validation/jquery-validation/blob/c1db10a34c0847c28a5bd30e3ee1117e137ca834/src/core.js#L1349
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(str);
}


export function dottedStringToMethodReference(input_string) {
    /** Convert a dotted string (e.g. izele.group.methodName) to a function reference */
    var methodComponents = input_string.split('.');
    var reference = window[methodComponents.shift()];

    while (methodComponents.length > 0) {
        reference = reference[methodComponents.shift()];
    }

    return reference
}


export function addToSentry(exception) {
    /** Send an exception to Sentry. To pass an error that's not been caught, create an error object, e.g. addToSentry(new TypeError("Some error text")) */
    // delay to ensure Sentry has been loaded
    window.setTimeout(function () {
        if (window.Sentry) {
            Sentry.captureException(exception);
        } else {
            console.log("Sentry logging called on dev: ", exception)
        }
    }, 3000);
}


export function createExpandingTextarea(textarea, minHeight, maxHeight) {
    // set default heights if not provided
    minHeight = typeof minHeight !== 'undefined' ? minHeight : 28;
    maxHeight = typeof maxHeight !== 'undefined' ? maxHeight : 500;

    textarea.oninput = function() {
        this.updateHeight();
    };

    textarea.updateHeight = function () {
        this.setHeight(Math.max(Math.min(textarea.scrollHeight, maxHeight), minHeight));
    };

    textarea.setHeight = function (height) {
        this.style.height = "";
        this.style.height = height + "px";
    }
}


/** Returns lowercase suffix from a filename string */
export function getFileSuffix(filename) {
    // https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504
    return filename.substr((~-filename.lastIndexOf(".") >>> 0) + 2).toLowerCase();
}


/** Convert image URI to File object, from: https://stackoverflow.com/a/43358515 */
export function dataURLtoFile(dataUrl, filename) {
    var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bStr = atob(arr[1]), n = bStr.length, u8arr = new Uint8Array(n);

    while(n--) {
        u8arr[n] = bStr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}

export function formatXHRResponse(xhr, method) {
    var errorMessage = "";
    var method_lookup = {
        'POST': gettext("adding the record"),
        'PATCH': gettext("making your changes"),
        'DELETE': gettext("deleting the record"),
        'GET': gettext("getting the data"),
        undefined: ""
    };

    if ('responseJSON' in xhr) {
        if (xhr.responseJSON instanceof Object) {
            var errors = $.map(xhr.responseJSON, function(value, index) {
                return [value];
            });
            errorMessage = gettext("Error(s):") + " " + errors.join(', ');

        } else {
            errorMessage = gettext("Error(s):") + " " + xhr.responseJSON;
        }

    } else {
        errorMessage = gettext("There was an error") + " " + method_lookup[method] + " (" + xhr.status + ": " + xhr.statusText + ")";
    }

    return errorMessage
}

// todo: how to write this...
// export function retryFunctionUntilTimeout() {
//
// }