import Vue from 'vue';
import L from 'leaflet';

export default {
    state: {
        pageData: {  // to be moved to new namespaced store
            pageDataLoaded: false,
            page_type: "",
            is_conservation_area: false,
            is_conservation_group: false,
            is_business: false,
            accepts_visitors: false,
            charges_entrance: undefined,
            membership_scheme: undefined,
            sells_products_services: undefined,
            has_amenities_subscription: false
        },
        spatialData: {  // to be moved
            orgCentroid: izele.organisationData.centroid,
            orgEnvelope: izele.organisationData.envelope,
            orgBoundary: izele.organisationData.boundary,
        },
        mapLayers: {  // to be moved
            organisation: {polygon: L.featureGroup(), marker: L.featureGroup()},
            surroundingOrganisations: {polygons: L.featureGroup(), markers: L.featureGroup(), triggerRedraw: null}  // triggerRedraw = something to watch in components - updated with timestamp
        },
        relationships: {},
        umbrellaRelationships: {
            isUmbrella: false,
            umbrellaChildren: [],
            isPartOfUmbrella: false,
            umbrellaParent: undefined,
            umbrellaParentLink: undefined
        }
    },
    mutations: {
        addInitialPageData: function (state, data) {
            state.pageData.page_type = data.page_type;
            state.pageData.is_conservation_area = data.is_conservation_area;
            state.pageData.is_conservation_group = data.is_conservation_group;
            state.pageData.is_business = data.is_business;
            state.pageData.accepts_visitors  = data.accepts_visitors;
            state.pageData.charges_entrance  = data.charges_entrance;
            state.pageData.membership_scheme  = data.membership_scheme;
            state.pageData.sells_products_services  = data.sells_products_services;

            state.pageData.pageDataLoaded = true;
        },
        updateOrgSubtype: function (state, data) {
            switch (data.subtype) {
                case "area":
                    state.pageData.is_conservation_area = data.subtype_state;
                    break;
                case "group":
                    state.pageData.is_conservation_group = data.subtype_state;
                    break;
                case "business":
                    state.pageData.is_business = data.subtype_state;
                    break;
            }
        },
        updateAcceptsVisitors: function (state, newValue) {
            state.pageData.accepts_visitors = newValue;
            if (!newValue) {
                state.pageData.charges_entrance = false;
            }
        },
        updateChargesEntrance: function (state, newValue) {
            state.pageData.charges_entrance = newValue;
        },
        updateMembershipScheme: function (state, newValue) {
            state.pageData.membership_scheme = newValue;
        },
        updateSellsProductsServices: function (state, newValue) {
            state.pageData.sells_products_services = newValue;
        },

        clearOrganisationLayers: function (state) {
            state.mapLayers.organisation.marker.clearLayers();
            state.mapLayers.organisation.polygon.clearLayers();
        },
        updateOrgCentroid: function (state, orgData) {
            // if data committed, use that to update the centroid and marker, otherwise redraw with generic marker
            if (orgData !== undefined) {
                state.spatialData.orgCentroid = orgData.centroid_4326;

                // only add layer if no boundary data
                if (orgData.property_boundary_4326 === undefined || orgData.property_boundary_4326 === null) {
                    var orgMarker = izele.mapping.createIconFromImage(orgData.organisation_details.get_organisation_logo);
                    state.mapLayers.organisation.marker.addLayer(izele.mapping.createMarker(orgData.centroid_4326, orgData.organisation_details, orgMarker));
                }

            } else {
                // this redraw with generic marker is for users deleting their boundary layer. Todo: create correct organisation marker with popup
                if (state.mapLayers.organisation.marker.getLayers().length === 0) {
                    state.mapLayers.organisation.marker.addLayer(izele.mapping.createMarker(state.spatialData.orgCentroid));
                }

            }


        },
        updateOrgEnvelope: function (state, orgData) {
            state.spatialData.orgEnvelope = orgData.user_provided_property_envelope;
        },
        updateOrgBoundary: function (state, orgData) {
            state.spatialData.orgBoundary = orgData.property_boundary_4326;

            // layer always added - only centroid checked to ensure not displayed over the top of this
            state.mapLayers.organisation.polygon.addLayer(izele.mapping.createPolygonLayer(orgData.property_boundary_4326, orgData.organisation_details, izele.mapping.styles.polygons.mainOrgPoly));

        },
        addSurroundingOrgBoundary: function (state, orgData) {
            state.mapLayers.surroundingOrganisations.polygons.addLayer(izele.mapping.createPolygonLayer(orgData.property_boundary_4326, orgData.organisation_details));
        },
        addSurroundingOrgMarker: function (state, orgData) {
            state.mapLayers.surroundingOrganisations.markers.addLayer(izele.mapping.createMarker(orgData.centroid_4326, orgData.organisation_details));
        },
        triggerRedraw: function (state) {
            state.mapLayers.surroundingOrganisations.triggerRedraw = Date.now();
        },
        updateIsUmbrella: function (state, isUmbrellaState) {
            // isUmbrellaState contains is_umbrella_parent (bool) and umbrella_children (array of org objects)
            state.umbrellaRelationships.isUmbrella = isUmbrellaState.is_umbrella_parent;
            state.umbrellaRelationships.umbrellaChildren = isUmbrellaState.umbrella_children;
        },
        addChildToUmbrella: function (state, child_object) {
            state.umbrellaRelationships.umbrellaChildren.push(child_object);
        },
        deleteChildFromUmbrella: function (state, child_id) {
            for (var i=0; i < state.umbrellaRelationships.umbrellaChildren.length; i++) {
                if (state.umbrellaRelationships.umbrellaChildren[i].organisation_id === child_id) {
                    state.umbrellaRelationships.umbrellaChildren.splice(i, 1);
                    break;
                }
            }
        },
        updateIsPartOfUmbrella: function (state, isPartOfUmbrellaState) {
            state.umbrellaRelationships.isPartOfUmbrella = isPartOfUmbrellaState.is_umbrella_child;
            state.umbrellaRelationships.umbrellaParent = isPartOfUmbrellaState.umbrella_parent;
            state.umbrellaRelationships.umbrellaParentLink = isPartOfUmbrellaState.umbrella_parent_link;
        },
        updateAllRelationships: function (state, updateData) {
            state.relationships = updateData;
        },
        updateSingleRelationship: function (state, orgData) {
            // remove whole element, then recreate to ensure vue reactivity maintained
            Vue.delete(state.relationships, orgData.id);

            for (var rel in orgData.relationships) {
                if (orgData.relationships[rel]) {
                    Vue.set(state.relationships, orgData.id, orgData);
                    break;
                }
            }
        }
    },
    actions: {
        updateOrganisationPositionLayers: function (context, data) {
            var dataHasBoundary = (data.property_boundary_4326 !== undefined && data.property_boundary_4326 !== null);
            var dataHasCentroid = (data.centroid_4326 !== undefined && data.centroid_4326 !== null);
            var dataHasEnvelope = (data.user_provided_property_envelope !== undefined && data.user_provided_property_envelope !== null);

            // don't clear if only envelope
            if (dataHasCentroid || dataHasBoundary) {
                context.commit('clearOrganisationLayers');
            }

            if (dataHasBoundary) {
                context.commit('updateOrgBoundary', data);
            }

            if (dataHasCentroid) {
                context.commit('updateOrgCentroid', data);
            }

            if (dataHasEnvelope) {
                context.commit('updateOrgEnvelope', data);
            }

            // trigger a re-draw of surrounding orgs if centroid/boundary changed
            if (dataHasCentroid || dataHasBoundary) {
                context.commit('triggerRedraw');
            }
        },
        clearOrganisationLayer: function (context, layer) {
            if (layer === 'envelope') {
                context.commit('updateOrgEnvelope', {user_provided_property_envelope: null});

            } else if (layer === 'boundary') {
                context.state.mapLayers.organisation.polygon.clearLayers();
                context.commit('updateOrgBoundary', {property_boundary_4326: null});
                context.commit('updateOrgCentroid');
            }

        },
        createSurroundingLayers: function (context, data) {
            context.state.mapLayers.surroundingOrganisations.polygons.clearLayers();
            context.state.mapLayers.surroundingOrganisations.markers.clearLayers();

            data.forEach(function (v) {
                if (v.property_boundary_4326 !== undefined && v.property_boundary_4326 !== null) {
                    context.commit('addSurroundingOrgBoundary', v);

                } else if (v.centroid_4326 !== undefined && v.centroid_4326 !== null) {
                    context.commit('addSurroundingOrgMarker', v);
                }

            }, this);
        },
        initialSetupOfRelationships: function (context) {
            // todo: get relationship data through ajax (retain 'is part of umbrella' through template)?
            context.commit('updateIsUmbrella', {
                is_umbrella_parent: izele.organisationData.umbrellaRelationships.is_umbrella_parent,
                umbrella_children: izele.organisationData.umbrellaRelationships.umbrella_children});
            context.commit('updateIsPartOfUmbrella', {
                is_umbrella_child: izele.organisationData.umbrellaRelationships.is_umbrella_child,
                umbrella_parent: izele.organisationData.umbrellaRelationships.umbrella_parent,
                umbrella_parent_link: izele.organisationData.umbrellaRelationships.umbrella_parent_link
            });

            context.commit('updateAllRelationships', izele.organisationData.relationships);
        }
    },
    getters: {
        getOrgCentroid: function (state) {
            return state.spatialData.orgCentroid;
        },
        getOrgEnvelope: function (state) {
            return state.spatialData.orgEnvelope;
        },
        getOrgBoundary: function (state) {
            return state.spatialData.orgBoundary;
        },
        getSurroundOrgTriggerRedraw: function (state) {
            return state.mapLayers.surroundingOrganisations.triggerRedraw;
        },
        getUmbrellaParentState: function (state) {
            return state.umbrellaRelationships.isUmbrella;
        },
        getUmbrellaChildState: function (state) {
            return state.umbrellaRelationships.isPartOfUmbrella;
        },
        getSortedRelationshipList: function (state) {
            var sortable = [];
            for (var key in state.relationships) {
                if (state.relationships[key].type === 'organisation') {
                    var ref = state.relationships[key].relationships;
                    if (ref.partner || ref.supporter || ref.beneficiary || ref.project_manager || ref.managed_project) {
                        sortable.push(state.relationships[key]);
                    }
                }

            }

            return sortable.sort(function (a, b) {
                var nameA = a.name.toUpperCase();
                var nameB = b.name.toUpperCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
        },
        getSortedProjectRelationshipList: function (state) {
            var sortable = [];
            for (var key in state.relationships) {
                if (state.relationships[key].type === 'project') {
                    var ref = state.relationships[key].relationships;
                    if (ref.partner || ref.supporter || ref.beneficiary || ref.project_manager || ref.managed_project) {
                        sortable.push(state.relationships[key]);
                    }
                }

            }

            return sortable.sort(function (a, b) {
                var nameA = a.name.toUpperCase();
                var nameB = b.name.toUpperCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
        },
        aboutPageRelationshipObject: function (state) {
            var return_object = {
                projects: [],
                members: state.umbrellaRelationships.umbrellaChildren,
                partners: [],
                supporters: [],
                beneficiaries: []
            };

            for (var key in state.relationships) {
                if (state.relationships[key].type === 'project') {

                    // if (state.relationships[key].relationships.managed_project) {
                        return_object.projects.push(state.relationships[key]);
                    // }

                } else {
                    var ref = state.relationships[key].relationships;

                    if (ref.partner) {
                        return_object.partners.push(state.relationships[key]);
                    }
                    if (ref.supporter) {
                        return_object.supporters.push(state.relationships[key]);
                    }
                    if (ref.beneficiary) {
                        return_object.beneficiaries.push(state.relationships[key]);
                    }
                }

            }

            // alphabetically sort each of the arrays in the return object
            Object.keys(return_object).forEach(function (key) {
                return_object[key] = return_object[key].sort(function (a, b) {
                    var nameA, nameB;

                    if (key === 'members') {
                        nameA = a.organisation_name.toUpperCase();
                        nameB = b.organisation_name.toUpperCase();
                    } else {
                        nameA = a.name.toUpperCase();
                        nameB = b.name.toUpperCase();
                    }

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
            });

            return return_object;
        },
        getNumberOfPartners: function (state) {
            var numberOfPartners = 0;
            for (var key in state.relationships) {
                if (state.relationships[key].status === "registered" && state.relationships[key].relationships.partner) {
                    numberOfPartners++;
                }
            }

            return numberOfPartners;
        }
    }
}