/**
Global search result element, can be an organisation, project, ...
    Props:
    * result - an individual search element

    Emitted events:
    * result-clicked - emitted when a result clicked
*/


<template>
    <div class="search-result-element" v-bind:class="recordSelected" v-on:click="selectRecord">
        <img v-bind:src="image" alt="">
        <div class="sre-container">
            <div>
                <i class="visible-xs">{{ singleCountry }}</i>
                <i class="hidden-xs">{{ multipleCountries }}</i>
                <i v-if="result.countries && result.geoname_location">,&nbsp;</i>
                <i v-if="result.geoname_location">{{ result.geoname_location }}</i>
                <div class="sre-type">{{ resultType }}</div>
            </div>

            <h4>{{ title }}</h4>
        </div>

        <div class="clearfix"></div>
    </div>
</template>

<script>
    export default {
        props: {
            result: {type: Object, required: true}
        },
        methods: {
            selectRecord: function () {
                this.$emit('result-clicked', [this.result.id, this.result.type]);  // both in case of future clash, e.g. org w/ species
            }
        },
        computed: {
            image: function () {
                if (this.result.type === "person") {
                    return this.result.avatar;
                }

                if (this.result.type === "country") {
                    return this.result.flag;
                }

                return this.result.logo;
            },
            title: function () {
                return this.result.name;
            },
            resultType: function () {
                return this.result.type.charAt(0).toUpperCase() + this.result.type.slice(1);
            },
            multipleCountries: function () {
                if (!this.result.countries) {
                    return "";
                }

                if (this.result.countries.length === 1) {
                    return this.result.countries[0]
                }

                var countryChars = 0;

                for (var i=0; i < this.result.countries.length; i++) {
                    countryChars += this.result.countries[i].length;
                    if (countryChars > 35) {
                        break;
                    }
                }

                return this.result.countries.slice(0, i).join(", ") + (this.result.countries.length > i ? "..." : "")


            },
            singleCountry: function () {
                if (this.result.countries) {
                    return this.result.countries[0]
                } else {
                    return ""
                }
            },
            recordSelected: function () {
                if (this.result.selected) {
                    return 'sre-selected';
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../styles/variables";

    .search-result-element {
        padding: 1px;
        height: 57px;
        margin: 0 0 8px 0;
        cursor: pointer;

        img {
            width: 55px;
            max-height: 55px;
            margin-right: 3px;
            float: left;
        }

        &:hover {
            @include standard-box-shadow;
            background-color: #EEE;
        }

        @media #{$bootstrap-break-xs} {
            height: 45px;

            img {
                width: 43px;
                height: 43px;
            }
        }
    }

    .sre-selected {
        @include standard-box-shadow;
        background-color: #EEE;
    }

    .sre-container {
        position: relative;
        margin: 0 0 0 58px;
        height: 55px;
        overflow-y: hidden;

        div:first-child {
            position: absolute;
            right: 3px; bottom: 2px; left: 0;

            div.sre-type {
                font-weight: bold;
                color: $standard-light-grey;
                font-size: 85%;
                position: absolute;
                right: 0;
                bottom: 1px;
            }

            i {
                float: left;
            }

            @media #{$bootstrap-break-xs} {
                i {
                    font-size: 90%;
                }
            }

        }

        h4 {
            margin: 0;
        }

        @media #{$bootstrap-break-xs} {
            height: 45px;

            h4 {
                font-size: 100%;
                font-weight: bold;
            }
        }
    }
</style>