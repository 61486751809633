import Vue from 'vue';
import * as Sentry from "@sentry/vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { capitalizeFirstLetter } from "../../js/tools/string";

if (!izele.debug) {
    Sentry.init({
        Vue,
        dsn: "https://e478d97ec42d491b8a3f0e6f9b86274f@o103081.ingest.sentry.io/272603",
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

Vue.config.productionTip = false;

Vue.filter('capitalize', function (value) {
    // if (!value) return '';
    // value = value.toString();
    // return value.charAt(0).toUpperCase() + value.slice(1)
    return capitalizeFirstLetter(value)
});

Vue.filter('pageLink', function (organisationObject) {
    // take an organisation object and return the canonical izele link
    var id, slug, type;

    if ("id" in organisationObject && "slug" in organisationObject && "type" in organisationObject) {
        id = organisationObject.id;
        slug = organisationObject.slug;
        type = organisationObject.type;

    } else if ("organisation_id" in organisationObject && "organisation_slug" in organisationObject && "type" in organisationObject) {
        id = organisationObject.organisation_id;
        slug = organisationObject.organisation_slug;
        type = organisationObject.type;

    } else {
        throw new TypeError("pageLink filter missing at least one property");
    }

    if (type === 'project') {
        return '/projects/' + id + '/' + slug + '/';
    } else {
        return '/' + id + '/' + slug + '/';
    }
});

Vue.filter("smartTruncate", function (text, limit) {
    // VueJS version of the Django smart_truncate filter in core
    limit = parseInt(limit, 10);
    if (!text) { return "" }
    if (isNaN(limit)) { return "" }

    if (text.length <= limit) {
        return text;
    }

    text = text.substr(0, limit);
    var words = text.split(" ").slice(0, -1);

    return words.join(" ") + "..."
});

// wrap i18n gettext in a mixin to allow access in Vue components
Vue.mixin({
    components: {
        FontAwesomeIcon
    },
    methods: {
        gettext: function(text) {
            return gettext(text)
        },
        capitalizeFirstLetter: function (str) {
            return capitalizeFirstLetter(str)
        },
        getTranslatedPageType: function (pageType) {
            switch (pageType) {
                case "organisation":
                    return this.gettext("organisation");
                case "project":
                    return this.gettext("project");
            }
            return "";
        }
    }
});