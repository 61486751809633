import {createZoomLayeredPageLayer, selectedPagePolygonStyle} from "../../../js/mapping/map-layers";
import {
    businessMarker,
    conservationAreaMarker,
    conservationGroupMarker, createActivitiesMarker,
    createLargeHighlightedPageMarker, projectMarker
} from "../../../js/mapping/markers";
import L from "leaflet";
import {formatPageMarkerPopup} from "../../../js/mapping/map-tools";

export function PageSpatialObject(objectFromJson) {
    // Object representing this page's spatial representation and core details
    var expectedParameters = ["page_id", "page_name", "page_logo", "page_url", "poly_zoom_threshold", "centroid_4326",
        "user_provided_property_envelope", "boundary_or_envelope_centroid", "property_boundary_4326"];
    for (var param in expectedParameters) {
        if (!param in objectFromJson) {
            throw ReferenceError("page-store--spatial: spatial object missing param: " + param);
        }
    }

    this.page_id = objectFromJson.page_id;
    this.page_name = objectFromJson.page_name;
    this.page_logo = objectFromJson.page_logo;
    this.page_url = objectFromJson.page_url;
    this.poly_zoom_threshold = objectFromJson.poly_zoom_threshold;
    this.centroid_4326 = objectFromJson.centroid_4326;
    this.user_provided_property_envelope= objectFromJson.user_provided_property_envelope;
    this.boundary_or_envelope_centroid = objectFromJson.boundary_or_envelope_centroid;
    this.property_boundary_4326 = objectFromJson.property_boundary_4326;
    this.type = objectFromJson.type;
    this.activities = [];

    // if objectFromJson has property timestamp_centroid_edited, create it (and env and poly)
    if (objectFromJson.timestamps) {
        this.timestamp_centroid_edited = objectFromJson.timestamps.timestamp_centroid_edited;
        this.timestamp_boundary_edited = objectFromJson.timestamps.timestamp_boundary_edited;
        this.timestamp_envelope_edited = objectFromJson.timestamps.timestamp_envelope_edited;
    }

    this.layer = createZoomLayeredPageLayer(
        this.property_boundary_4326,
        this.centroid_4326,
        this.user_provided_property_envelope,
        this.boundary_or_envelope_centroid ? this.boundary_or_envelope_centroid : this.centroid_4326,
        this.poly_zoom_threshold, selectedPagePolygonStyle, createLargeHighlightedPageMarker(this.page_logo), this, true);
}

export function SurroundingSpatialObject(objectFromJson, allowEmptyCentroid) {
    // Object representing a surrounding page's spatial representation and some details
    allowEmptyCentroid = allowEmptyCentroid ? allowEmptyCentroid : false;

    var expectedParameters = ["page_id", "page_name", "page_logo", "page_url", "icon", "centroid"];  // centroid key always there, just sometimes null
    for (var param in expectedParameters) {
        if (!(expectedParameters[param] in objectFromJson)) {
            throw ReferenceError("page-store--spatial: surrounding spatial object missing param: " + param);
        }
    }

    if (!allowEmptyCentroid && !objectFromJson.centroid) {
        throw ReferenceError("page-store--spatial: surrounding spatial object missing centroid and allowEmptyCentroid set to false")
    }

    this.page_id = objectFromJson.page_id;
    this.page_name = objectFromJson.page_name;
    this.page_logo = objectFromJson.page_logo;
    this.page_url = objectFromJson.page_url;
    this.type = objectFromJson.type;
    this.centroid = objectFromJson.centroid;
    this.icon = objectFromJson.icon;
    this.activities = [];

    var pageIcon = conservationGroupMarker();
    switch (objectFromJson.icon ) {
        case "area":
            pageIcon = conservationAreaMarker();
            break;
        case "business":
            pageIcon = businessMarker();
            break;
        case "project":
            pageIcon = projectMarker();
            break
    }

    if ("acronym" in objectFromJson) {
        this.acronym = objectFromJson.acronym;
    }

    if ("description" in objectFromJson) {
        this.description = objectFromJson.description;
    }

    var labelledMetadata = {
        "logo": this.page_logo,
        "name": this.page_name,
        "type": this.type,
        "url": this.page_url
    };

    this.layer = L.geoJson(this.centroid, {
        onEachFeature: function (feature, layer) {
            layer.bindPopup(formatPageMarkerPopup(labelledMetadata));
        },
        pointToLayer: function (feature, latlng) {
            var marker = L.marker(latlng, {icon: pageIcon});
            marker.page_id = objectFromJson.page_id;
            return marker;
        },
    });
}

export function GetPageWithActivitiesObject(pageObject, activitiesSearched, allActivities, isThisPage) {
    if (pageObject.activities.length === 0) {
        return;
    }

    var activitiesMatchingSearch = [];
    var allActivitiesOnPage = [];

    pageObject.activities.forEach(function (activity) {
        var matchingActivity = activitiesSearched.find(function (e) {
            return e.id === activity.id
        });
        if (matchingActivity) {
            activitiesMatchingSearch.push(matchingActivity)
        }

        var tempActivity = allActivities.find(function (e) {
            return e.id === activity.id
        });
        tempActivity.status = activity.status;  // open/closed because of covid
        if (tempActivity) {
            allActivitiesOnPage.push(tempActivity)
        }
    });

    if (activitiesMatchingSearch.length === 0 && !isThisPage) {
        return;
    }

    var metadata = {
        "logo": pageObject.page_logo,
        "name": pageObject.page_name,
        "type": pageObject.type,
        "url": pageObject.page_url
    };

    var centroid;
    if (pageObject.centroid) {
        centroid = pageObject.centroid
    } else if (pageObject.centroid_4326) {
        centroid = pageObject.centroid_4326
    } else if (pageObject.boundary_or_envelope_centroid) {
        centroid = pageObject.boundary_or_envelope_centroid
    }

    return createActivitiesMarker(centroid, pageObject, metadata, activitiesMatchingSearch, allActivitiesOnPage, isThisPage);
}