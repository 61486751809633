import L from 'leaflet';
import { formatPageMarkerPopup } from "./map-tools";

var izeleWmsUrl = "https://izele.org/geoserver/izele/wms";
export var mapboxPublicToken = "pk.eyJ1IjoibWFyaW5lbWFwcGluZyIsImEiOiJzM0Zxa2NJIn0.CsE3SQa_5YzTxA50eDsW8w";

export var osmAttributionText = gettext("&copy; <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> &copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>");
export var osmSmallAttribution = gettext("&copy; <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> &copy; <a href='http://www.openstreetmap.org/copyright'>OSM</a>");
var mapboxAttributionText = gettext("&copy; <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> &copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong> &copy; <a href='https://www.digitalglobe.com/' target='_blank'>DigitalGlobe</a>");

export const mapBoxStreets = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=" + mapboxPublicToken;


export var MapBoxBackgroundMapping = L.tileLayer(
    mapBoxStreets,
    {attribution: osmAttributionText}
);

export var MapBoxSatelliteMapping = L.tileLayer(
    "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=" + mapboxPublicToken,
    {attribution: mapboxAttributionText}
);


export var pageBoundariesTileLayer = L.tileLayer.wms(
    izeleWmsUrl, {
        layers: "izele:page-boundaries",
        format: 'image/png', transparent: true, tiled: true
});


// country id set when imported and before added to map (e.g. this_var.options.env = "id:508")
export function createCountryBoundariesTileLayer(countryId) {
    return L.tileLayer.wms(
        izeleWmsUrl, {
            layers: "izele:country-borders",
            format: 'image/png', transparent: true, tiled: true,
            viewparams: "id:" + countryId
        }
    );
}


export var selectedPagePolygonStyle = {
    color: "#666699", "weight": 2, fillOpacity: 0.25
};

// export var surroundingPagesPolygonStyle = {
//     color: "#339966", "weight": 2, fillOpacity: 0.25
// };


export function createZoomLayeredPageLayer(polygon, chosenCentroid, envelope, calculatedCentroid, zoomThreshold, polygonStyle, pointIcon, metadata, pushToTop) {
    // Create a zoom layered element from geoJSON that will switch at the zoomThreshold
    var layer;
    var labelledMetadata = {
        "logo": metadata.page_logo,
        "name": metadata.page_name,
        "type": metadata.type,
        "url": metadata.page_url
    };

    if (!polygon) {
        // if no poly, prefer a centroid, but otherwise create invisible polygon of envelope
        if (chosenCentroid) {
            layer = L.geoJson(chosenCentroid, {
                onEachFeature: function (feature, layer) {
                    if (metadata) {
                        layer.bindPopup(formatPageMarkerPopup(labelledMetadata));
                    }
                },
                pointToLayer: function (feature, latlng) {
                    return L.marker(latlng, {icon: pointIcon});
                },
            })

        } else if (envelope) {
            layer = L.geoJSON(envelope, {
                style: {
                    "opacity": 0,
                    "fillOpacity": 0
                }
            })
        }

    } else {
        layer = L.geoJSON(polygon, {
            scaleDependentRender: true,
            currentLayer: "polygon",
            onEachFeature: function (feature, layer) {
                if (metadata) {
                    layer.bindPopup(formatPageMarkerPopup(labelledMetadata));
                }
            },
            pointToLayer: function (feature, latlng) {
                if (pushToTop) {
                    return L.marker(latlng, {icon: pointIcon, zIndexOffset: 1000});
                }
                return L.marker(latlng, {icon: pointIcon});
            },
            style: function (feature) {
                if (feature.geometry.type === "MultiPolygon" || feature.geometry.type === "Polygon") {
                    return polygonStyle;
                }
            }
        }).on("layerzoomend", function (e) {
            if (zoomThreshold) {
                if (e.target._map.getZoom() >= zoomThreshold) {
                    // show a polygon, and not already showing one
                    if (e.target.options.currentLayer !== "polygon") {
                        layer.clearLayers();
                        layer.addData(polygon);
                        e.target.options.currentLayer = "polygon";
                    }

                } else {
                    if (e.target.options.currentLayer !== "point") {
                        layer.clearLayers();
                        if (calculatedCentroid) {
                            layer.addData(calculatedCentroid);
                        }
                        e.target.options.currentLayer = "point";
                    }

                }
            }
        });
    }

    return layer;
}
