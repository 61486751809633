/** A message for the user from an internal process (e.g. post instagram authentication) */

<template>
    <div class="usr-msg-overlay" v-on:click="closeWindow">
        <div class="usr-msg-content" v-on:click.stop>
            <font-awesome-icon icon="times" v-on:click="closeWindow" style="cursor: pointer"/>
            <h2 v-bind:class="titleClass">
                <font-awesome-icon icon="info-circle" v-if="this.messageContent.level === 'info'"/>
                <font-awesome-icon icon="check-circle" v-if="this.messageContent.level === 'success'"/>
                <font-awesome-icon icon="exclamation-triangle" v-if="this.messageContent.level === 'warning' || this.messageContent.level === 'error'"/>
                {{ messageContent.title }}
            </h2>
            <div v-html="messageContent.content"></div>
        </div>
    </div>
</template>

<script>
    import { library } from "@fortawesome/fontawesome-svg-core";
    import { faTimes, faExclamationTriangle, faInfoCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
    library.add(faTimes, faExclamationTriangle, faInfoCircle, faCheckCircle);


    export default {
        props: {
            messageContent: {required: true}
        },
        methods: {
            closeWindow: function () {
                this.$emit('close-message');
            }
        },
        computed: {
            titleClass: function () {
                switch (this.messageContent.level) {
                    case "info":
                        return "text-info";
                    case "success":
                        return "text-success";
                    case "warning":
                        return "text-warning";
                    case "error":
                        return "text-danger";
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../styles/variables";

    .usr-msg-overlay {
        position: absolute;
        z-index: 9999;
        top: 0; right: 0; bottom: 0; left: 0;
        background-color: $standard-modal-background;
    }

    .usr-msg-content {
        position: relative;
        background-color: white;
        width: 700px;
        margin: 200px auto 0 auto;
        padding: 15px;
        border-radius: $izele-border-radius;

        h2 {
            margin-bottom: 10px;
        }

        @media #{$bootstrap-break-sm} {
            width: 80%;
        }

        > svg {
            position: absolute;
            top: 12px; right: 15px;
            font-size: 180%;
            cursor: pointer;

            &:hover {
                color: $standard-light-grey;
            }
        }
    }
</style>