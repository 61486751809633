var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { class: _vm.notificationLive }, [
    _vm.notification.notification_type === "message" ||
    _vm.notification.notification_type === "action"
      ? _c(
          "a",
          {
            staticClass: "menu-notification-element",
            attrs: { href: _vm.relevantLink },
          },
          [
            _c("h3", [_vm._v(_vm._s(_vm.notification.notification_title))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.notification.notification_message))]),
            _vm._v(" "),
            _c("h4", [_vm._v(_vm._s(_vm.notification.time_string))]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.notification.notification_type === "action_object"
      ? _c(
          "a",
          {
            staticClass: "menu-notification-element",
            attrs: { href: _vm.relevantLink },
          },
          [
            _c("h3", [_vm._v(_vm._s(_vm.notification.notification_title))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.notification.notification_message))]),
            _vm._v(" "),
            _c("h4", [_vm._v(_vm._s(_vm.notification.time_string))]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }