// todo: nest this inside page, same as ecotourism store - currently under root/pageSpatial
import L from 'leaflet';
import { addToSentry } from "../../../js/tools/general";
import { PageSpatialObject, SurroundingSpatialObject, GetPageWithActivitiesObject } from "./page-store--spatial--tools";

// noinspection JSUnusedGlobalSymbols,JSUnresolvedFunction
export default {
    namespaced: true,
    state: {
        thisPageDataLoaded: false,
        thisPage: {},
        otherPages: [],
        mapReference: null,
        showingActivityLayers: false,
        mapLayers: {
            thisPage: L.featureGroup(),
            thisPageWithActivities: L.featureGroup(),
            otherPages: L.markerClusterGroup({maxClusterRadius: 40}),
            pagesWithActivities: L.markerClusterGroup({maxClusterRadius: 40})
        },
        activitiesSearched: [],
        numberPagesWithSearchedActivities: 0
    },
    mutations: {
        setMapReference: function (state, mapRef) {
            state.mapReference = mapRef

            // enable bootstrap tooltips in popups
            state.mapReference.on("popupopen", function (e) {
                $(e.target._container).find('[data-toggle="tooltip"]').tooltip({html: true, container: "body"});
            })
        },
        updateDataForThisPage: function (state, spatialObject) {
            if (state.mapReference) {
                // on first load, map isn't loaded until the promise from the action that calls this completes
                // as this requires a redraw, remove the existing layer, update it and add it again later
                state.mapLayers.thisPage.removeFrom(state.mapReference);
            }

            state.thisPage = spatialObject;
            state.mapLayers.thisPage = L.featureGroup();
            state.mapLayers.thisPage.addLayer(state.thisPage.layer);

            state.thisPageDataLoaded = true;

            if (state.mapReference) {
                state.mapLayers.thisPage.addTo(state.mapReference);
                state.mapReference.fitBounds(state.mapLayers.thisPage.getBounds(), {maxZoom: 14});
                state.mapReference.invalidateSize();
            }
        },
        addSurroundingPage: function (state, spatialObject) {
            state.otherPages.push(spatialObject);
            if(spatialObject.layer) {
                state.mapLayers.otherPages.addLayer(spatialObject.layer);
            }
        },
        updateThisPageWithActivities: function (state, pageObject) {
            state.thisPage.activities = pageObject.activities;
        },
        updateSurroundPageWithActivities: function (state, pageObject) {
            for (var i=0; i < state.otherPages.length; i++) {
                if (state.otherPages[i].page_id === pageObject.id) {
                    state.otherPages[i].activities = pageObject.activities;
                    break;
                }
            }
        },
        addActivityToSearched: function (state, activityObject) {
            state.activitiesSearched.push(activityObject);
        },
        removeActivityFromSearched: function (state, activityId) {
            state.activitiesSearched = state.activitiesSearched.filter(function (e) {
                return e.id !== activityId
            });
        },
        enableActivitySearchLayer: function (state) {
            state.mapLayers.thisPage.remove();
            state.mapLayers.thisPageWithActivities.addTo(state.mapReference);

            state.mapLayers.otherPages.remove();
            state.mapLayers.pagesWithActivities.addTo(state.mapReference);
        },
        disableActivitySearchLayer: function (state) {
            state.mapLayers.thisPageWithActivities.remove();
            state.mapLayers.thisPage.addTo(state.mapReference);

            state.mapLayers.pagesWithActivities.remove();
            state.mapLayers.otherPages.addTo(state.mapReference);
        }
    },
    actions: {
        updateThisPageLayer: function (context, data) {
            try {
                context.commit("updateDataForThisPage", new PageSpatialObject(data));
            } catch (e) {
                addToSentry(e);
            }
        },
        addSurroundingLayers: function (context, data) {
            try {
                data.forEach(function (dataObject) {
                    if (dataObject.centroid) {
                        context.commit("addSurroundingPage", new SurroundingSpatialObject(dataObject))
                    }
                });
            } catch (e) {
                addToSentry(e);
            }
        },
        updateLayersWithActivities: function (context, data) {
            try {
                data[0].forEach(function (pageObject) {
                    pageObject.show = true;

                    if (pageObject.id === data[1]) {  // data is an array, the second element of which is the page id
                        context.commit("updateThisPageWithActivities", pageObject);
                    } else {
                        context.commit("updateSurroundPageWithActivities", pageObject);
                    }
                });

            } catch (e) {
                addToSentry(e);
            }
        },
        addActivityToSearchedAndUpdate: function (context, activity) {
            context.commit("addActivityToSearched", activity);
            context.dispatch("switchMarkerLayers").then(function () {
                context.dispatch("updateActivitiesLayer");
            });
        },
        removeActivityFromSearchedAndUpdate: function (context, activityId) {
            context.commit("removeActivityFromSearched", activityId);
            context.dispatch("switchMarkerLayers").then(function () {
                context.dispatch("updateActivitiesLayer");
            });
        },
        switchMarkerLayers: function (context) {
            if (context.state.activitiesSearched.length > 0) {
                if (!context.state.mapReference.hasLayer(context.state.mapLayers.pagesWithActivities)) {
                    context.commit("enableActivitySearchLayer");
                }

            } else {
                context.commit("disableActivitySearchLayer");
            }
        },
        updateActivitiesLayer: function (context) {
            context.state.mapLayers.pagesWithActivities.clearLayers();
            context.state.mapLayers.thisPageWithActivities.clearLayers();

            context.state.numberPagesWithSearchedActivities = 0;

            if (context.state.activitiesSearched.length > 0) {
                context.state.otherPages.forEach(function(pageObject) {
                    var activitiesObject = GetPageWithActivitiesObject(pageObject, context.state.activitiesSearched, context.rootState.page.allActivities, false);
                    if (activitiesObject) {
                        context.state.numberPagesWithSearchedActivities++;
                        context.state.mapLayers.pagesWithActivities.addLayer(activitiesObject);
                    }
                });

                var thisPagesActivities = GetPageWithActivitiesObject(context.state.thisPage, context.state.activitiesSearched, context.rootState.page.allActivities, true);
                if (thisPagesActivities) {
                    context.state.mapLayers.thisPageWithActivities.addLayer(thisPagesActivities);
                }


            }
        }
    },
    getters: {
        pageHasLocation: function (state) {
            return state.thisPage.page_id && (state.thisPage.centroid_4326 || state.thisPage.user_provided_property_envelope || state.thisPage.property_boundary_4326)
        },
        activitiesSearchedArray: function (state) {
            return state.activitiesSearched;
        },
        numberSearchResults: function (state) {
            return state.numberPagesWithSearchedActivities;
        },
        thisPageSpatialType: function (state) {
            if (state.thisPage.property_boundary_4326) {
                return "polygon"
            } else if (state.thisPage.user_provided_property_envelope) {
                return "envelope"
            } else if (state.thisPage.centroid_4326) {
                return "point"
            }

            return null;
        }
    }
}