/** Menu notifications - main icon and container */

<template>
    <div class="menu-notifications" style="float: left">
        <div class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <font-awesome-icon icon="bell"/>
            <div v-if="liveNotifications > 0" class="menu-notification-number">{{ liveNotifications }}</div>
        </div>

        <ul class="dropdown-menu dropdown-menu-right" style="width: 300px;">
            <MenuNotificationElement v-for="n in notifications.slice(0, $parent.maxNotifications)"
                                     v-bind:key="n.id"
                                     v-bind:notification="n"
                                     v-if="notifications.length > 0">
            </MenuNotificationElement>

            <li v-if="!$store.state.notifications.loading && notifications.length === 0">{{ gettext("No notifications found") }}</li>

            <li v-if="$store.state.notifications.loading">{{ gettext("Loading notifications") }}</li>

            <li role="separator" class="divider"></li>
            <li>
                <a v-bind:href="notificationUrl">{{ gettext("See all notifications") }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    import MenuNotificationElement from './menu-notification-element.vue';

    import { library } from "@fortawesome/fontawesome-svg-core";
    import { faBell } from "@fortawesome/free-solid-svg-icons";
    library.add(faBell);


    export default {
        components: {
            MenuNotificationElement
        },
        props: {
            'notifications': {type: Array, required: true},
            'live-notifications': {type: Number, required: true}
        },
        data: function () {
            return {
                notificationUrl: izele.urls.notifications
            }
        }
    }
</script>

<style lang="scss">
    @import "../../styles/common_styles.scss";

    .menu-notifications {
        position: relative;  /* needed for bootstrap dropdown */
        top: 2px;

        svg {
            font-size: 130%;
        }

        & > div {
            position: relative;
            cursor: pointer;

            &:hover {
                color: #ffd900;
            }
        }

        .menu-notification-number {
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            font-size: 90%;
            font-weight: bold;
            text-align: center;
            color: red;
        }
    }


    .menu-notification-viewed {
        h3, p, h4 {
            color: $standard-light-grey;
        }
    }

</style>