var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "usr-msg-overlay", on: { click: _vm.closeWindow } },
    [
      _c(
        "div",
        {
          staticClass: "usr-msg-content",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("font-awesome-icon", {
            staticStyle: { cursor: "pointer" },
            attrs: { icon: "times" },
            on: { click: _vm.closeWindow },
          }),
          _vm._v(" "),
          _c(
            "h2",
            { class: _vm.titleClass },
            [
              this.messageContent.level === "info"
                ? _c("font-awesome-icon", { attrs: { icon: "info-circle" } })
                : _vm._e(),
              _vm._v(" "),
              this.messageContent.level === "success"
                ? _c("font-awesome-icon", { attrs: { icon: "check-circle" } })
                : _vm._e(),
              _vm._v(" "),
              this.messageContent.level === "warning" ||
              this.messageContent.level === "error"
                ? _c("font-awesome-icon", {
                    attrs: { icon: "exclamation-triangle" },
                  })
                : _vm._e(),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.messageContent.title) +
                  "\n        "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.messageContent.content) },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }