import L from 'leaflet';
import { capitalizeFirstLetter } from "../tools/string";
import { formatPageMarkerPopup } from "./map-tools";

import { icon } from "@fortawesome/fontawesome-svg-core";
import { faIzAmenities, faIzProject } from "../tools/icons";
import { faTreeAlt } from "@fortawesome/pro-regular-svg-icons";
import { faUserFriends } from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faIzEmptyMarker } from "../tools/icons";
import { faBan } from "@fortawesome/pro-light-svg-icons";


const iconDir = "/static/img/mapping/";


export function conservationGroupMarker() {
    return faMarker(faUserFriends, "rgb(0, 102, 51)")
}


export function businessMarker() {
    return faMarker(faIzAmenities, "rgb(0, 102, 51)")
}


export function conservationAreaMarker() {
    return faMarker(faTreeAlt, "rgb(0, 102, 51)")
}


export function projectMarker() {
    return faMarker(faIzProject, "rgb(0, 102, 51)", "rgb(0, 102, 51)", {"top": "45%"})
}


export function visitedIcon() {
    return L.icon({
        iconUrl: iconDir + "map-marker-visited.svg",
        iconRetinaUrl: iconDir + "map-marker-visited.svg",
        shadowUrl: iconDir + "marker-shadow.png",
        iconSize:     [30, 40],
        shadowSize:   [41, 41],
        iconAnchor:   [15, 40],
        shadowAnchor: [14, 40],
        popupAnchor:  [0, -42]
    });
}


export function faMarker(faIconObject, markerColor, iconColor, otherIconStyles) {
    markerColor = typeof markerColor !== "undefined" ? markerColor : "#000";
    iconColor = typeof iconColor !== "undefined" ? iconColor : markerColor;

    var iconStyles = {"color": iconColor};
    if (typeof otherIconStyles !== "undefined") {
        Object.assign(iconStyles, otherIconStyles);
    }

    return L.divIcon({
        className: "fa-icon-marker",
        iconAnchor:   [18, 48],
        popupAnchor:  [0, -48],
        html:
            "<div>" +
                icon(faIzEmptyMarker, {styles: {"color": markerColor}}).html +
                "<div>" +
                    icon(faIconObject, {styles: iconStyles}).html +
                "</div>" +
            "</div>"
    });
}


export function createLargeHighlightedPageMarker(markerImage) {
    return L.divIcon({
        className: "map-icon-pages",
        iconAnchor:   [24, 64],
        popupAnchor:  [0, -64],
        html:
            "<div>" +
                icon(faIzEmptyMarker, {styles: {"color": "#251F38"}}).html +
                "<div>" +
                    "<img src='" + markerImage + "' alt=''>" +
                "</div>" +
            "</div>"
    });
}


export function createPageMarker(markerObject, idToShowPageLogoFor) {
    var displayIcon;

    if (idToShowPageLogoFor && markerObject.id === idToShowPageLogoFor) {
        displayIcon = createLargeHighlightedPageMarker(markerObject.logo)

    } else {
        switch (markerObject.icon) {
            case "business":
                displayIcon = businessMarker();
                break;

            case "group":
                displayIcon = conservationGroupMarker();
                break;

            case "project":
                displayIcon = projectMarker();
                break;

            default:
                displayIcon = conservationAreaMarker();
        }
    }

    return L.marker(
        [markerObject.centroid.coordinates[1], markerObject.centroid.coordinates[0]],
        {icon: displayIcon}).bindPopup(formatPageMarkerPopup(markerObject))
}


export function createActivitiesMarker(centroid, pageObject, metadata, searchedActivities, allActivities, isThisPage) {
    // todo: there is getting to be a lot of replication, sort this out...
    if (searchedActivities.length === 0) {
        var noActivityIcon = createLargeHighlightedPageMarker(metadata.logo);

        return L.geoJson(centroid, {
            onEachFeature: function (feature, layer) {
                layer.bindPopup(formatPageMarkerPopup(metadata, allActivities));
            },
            pointToLayer: function (feature, latlng) {
                return L.marker(latlng, {icon: noActivityIcon, zIndexOffset: 1000});
            },
        });
    }

    function drawActivitiesBox(boxReference, activity) {
        var iconForBox = "";

        if (activity) {
            iconForBox = "<img src='" + activity.activity_image + "' alt=''>";
        } else {
            iconForBox = icon(faPlus).html
        }
        if (activity && activity.status === "closed_covid") {
            return "<div class='activity-box " + boxReference + "'>" + iconForBox + "<div>" + icon(faBan).html + "</div></div>"
        } else {
            return "<div class='activity-box " + boxReference + "'>" + iconForBox + "</div>"
        }

    }

    var centralIcon = faUserFriends;

    switch (pageObject.icon) {
        case "area":
            centralIcon = faTreeAlt;
            break;
        case "business":
            centralIcon = faIzAmenities;
            break;
        case "project":
            centralIcon = faIzProject;
            break
    }

    var markerClassName = "page-marker-with-activities";
    var iconAnchor = [40, 65];
    var popupAnchor = [0, -65];

    if (searchedActivities.length <= 2) {
        markerClassName += " one-two-activities";
        iconAnchor = [27, 65];
        popupAnchor = [0, -65];
    }

    if (isThisPage) {
        markerClassName += " this-page-activities";
        iconAnchor = [45, 84];
        popupAnchor = [0, -84];

        if (searchedActivities.length <= 2) {
            iconAnchor = [40, 80];
            popupAnchor = [0, -80];
        }
    }

    var activity1 = "", activity2 = "", activity3 = "", activity4 = "";

    // update searchedActivities to include status. Maybe look at simplifying the whole search system
    searchedActivities.forEach(function (searchActivity) {
        pageObject.activities.forEach(function (pageActivity) {
            if (pageActivity.id === searchActivity.id) {
                searchActivity.status = pageActivity.status
            }
        })
    });

    if (searchedActivities.length <= 2) {
        activity1 = drawActivitiesBox("activity2", searchedActivities[0]);

        if (searchedActivities.length === 2) {
            activity2 = drawActivitiesBox("activity3", searchedActivities[1]);
        }


    } else {
        activity1 = drawActivitiesBox("activity1", searchedActivities[0]);
        activity2 = drawActivitiesBox("activity2", searchedActivities[1]);
        activity3 = drawActivitiesBox("activity3", searchedActivities[2]);

        if (searchedActivities.length === 4) {
            activity4 = drawActivitiesBox("activity4", searchedActivities[3]);

        } else if (searchedActivities.length > 4) {
            // replace last with a plus
            activity4 = drawActivitiesBox("activity4", undefined);
        }
    }


    var innerHtml;
    if (isThisPage) {
        // this is a partial duplicate of createLargeHighlightedPageMarker, resolve this?
        innerHtml = "" +
            "<div>" +
                activity1 + activity2 + activity3 + activity4 +

                "<div class='main-marker this-page-marker'>" +
                    icon(faIzEmptyMarker, {styles: {"color": "#251F38"}}).html +
                    "<div>" +
                        "<img src='" + metadata.logo + "' alt=''>" +
                    "</div>" +
                "</div>" +
            "</div>";

    } else {
        innerHtml = "" +
            "<div>" +
                activity1 + activity2 + activity3 + activity4 +

                "<div class='main-marker'>" +
                    icon(faIzEmptyMarker, {styles: {"color": "rgb(0, 102, 51)"}}).html +
                    "<div>" +
                        icon(centralIcon, {styles: {"color": "rgb(0, 102, 51)"}}).html +
                    "</div>" +
                "</div>" +
            "</div>";

    }

    var activitiesIcon = L.divIcon({
        className: markerClassName,
        iconAnchor: iconAnchor,
        popupAnchor: popupAnchor,
        html:
            "<div>" + innerHtml + "</div>"
    });

    return L.geoJson(centroid, {
        onEachFeature: function (feature, layer) {
            layer.bindPopup(formatPageMarkerPopup(metadata, allActivities));
        },
        pointToLayer: function (feature, latlng) {
            if (isThisPage) {
                return L.marker(latlng, {icon: activitiesIcon, zIndexOffset: 1000});
            } else {
                return L.marker(latlng, {icon: activitiesIcon});
            }
        },
    });
}