var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "general-search form-group-sm",
      class: { "general-search-frontpage": _vm.isFrontpage },
    },
    [
      _c("form", { attrs: { autocomplete: "off" } }, [
        _c("input", {
          staticClass: "form-control",
          attrs: { title: _vm.placeholder, placeholder: _vm.placeholder },
          on: {
            input: function ($event) {
              _vm.searchText = $event.target.value
            },
            keyup: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.changeSelected("up")
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.changeSelected("down")
              },
            ],
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.changePage.apply(null, arguments)
            },
            focus: function ($event) {
              return _vm.showResultsAndScrollPage($event)
            },
            blur: _vm.hideResultsAfterRender,
          },
        }),
      ]),
      _vm._v(" "),
      _vm.showResults
        ? _c("div", { staticClass: "search-results" }, [
            _c(
              "div",
              [
                _vm._l(_vm.results, function (result) {
                  return _c("result-element", {
                    key: result.id,
                    attrs: { result: result },
                    on: { "result-clicked": _vm.resultClicked },
                  })
                }),
                _vm._v(" "),
                _vm.results.length === 0 &&
                _vm.searchText.length > 0 &&
                !_vm.firstLoad
                  ? _c("div", [
                      _c("i", [
                        _vm._v(_vm._s(_vm.gettext("No results found"))),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.results.length === 0 && _vm.searchText.length === 0
                  ? _c("div", { staticClass: "sr-pre-msg" }, [
                      _c("i", [_vm._v(_vm._s(_vm.placeholder))]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.firstLoad && _vm.searchText.length > 0
                  ? _c(
                      "div",
                      { staticClass: "sr-searching loading-ellipsis" },
                      [_vm._v(_vm._s(_vm.gettext("Loading results...")))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchError
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.searchError)),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }