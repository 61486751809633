export default {
    state: {
        documents: []
    },
    mutations: {
        addSingleDocument: function (state, newDocument) {
            state.documents.unshift(newDocument);
        },
        addBulkDocuments: function (state, newDocuments) {
            state.documents = newDocuments;
        },
        removeDocument: function (state, documentId) {
            state.documents.forEach(function (doc, index) {
                if (doc.id === documentId) {
                    state.documents.splice(index, 1);
                }
            }, this);
        },
        updateDocumentMetadata: function (context, updatedDocument) {
            context.documents.forEach(function (doc) {
                if (doc.id === updatedDocument.id) {
                    doc.document_title = updatedDocument.document_title;
                    doc.document_description = updatedDocument.document_description;
                }
            });
        }
    },
    actions: {
        addDocuments: function (context, data) {
            context.commit('addBulkDocuments', data);
        }
    }
}