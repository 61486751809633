/** Menu notifications - elements in dropdown list */

<template>
    <li v-bind:class="notificationLive">
        <a class="menu-notification-element"
                v-if="notification.notification_type === 'message' || notification.notification_type === 'action'"
                v-bind:href="relevantLink" >
            <h3>{{ notification.notification_title }}</h3>
            <p>{{ notification.notification_message }}</p>
            <h4>{{ notification.time_string }}</h4>
        </a>

        <a class="menu-notification-element"
                v-if="notification.notification_type === 'action_object'"
                v-bind:href="relevantLink">
            <h3>{{ notification.notification_title }}</h3>
            <p>{{ notification.notification_message }}</p>
            <h4>{{ notification.time_string }}</h4>
        </a>
    </li>
</template>

<script>
    export default {
        props: {
            'notification': {type: Object, required: true}
        },
        computed: {
            notificationLive: function () {
                return {
                    'menu-notification-viewed': !this.notification.notification_live
                };
            },
            relevantLink: function () {
                if (this.notification.related_object) {
                    return this.notification.related_object;
                } else {
                    return this.$parent.notificationUrl;
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../styles/common_styles.scss";

    .menu-notification-element {
        margin: 2px;
        padding: 2px !important;
        border: 1px solid grey;
        border-radius: 2px;
        cursor: pointer;
        display: block;

        &:hover {
            @include standard-box-shadow;
        }

        h3 {
            margin: 0;
            font-size: 100%;
            font-weight: bold;
            white-space: normal;
        }

        p {
            margin: 0;
            font-size: 90%;
            white-space: pre-line;
        }

        h4 {
            margin: 0;
            font-size: 90%;
            font-style: italic;
        }
    }
</style>