import Vue from 'vue';

export default {
    state: {
        species: []
    },
    mutations: {
        addInitialSpeciesList: function (state, species_list) {
            state.species = state.species.concat(species_list);
        },
        addNewSpecies: function (state, species) {
            state.species.push(species)
        },
        removeSpecies: function (state, species_id) {
            state.species.forEach(function (item, index, object) {
                if (item.id === species_id) {
                    object.splice(index, 1);
                }
            });
        },
        updateSpeciesNotability: function (state, data) {
            state.species.forEach(function (item, index, object) {
                if (item.id === data[0]) {
                    Vue.set(object[index], 'species_is_notable', data[1]);
                }
            });
        }
    },
    actions: {},
    getters: {
        // todo: is this the best way of doing it (e.g. with a search filter on the other end) - or run through once and return an object with sub-arrays?
        speciesList__notable: function (state) {
            return state.species.filter(function (elem) {
                if (elem.species_is_notable) {
                    return elem
                }
            });
        },
        speciesList__mammals: function (state) {
            return state.species.filter(function (elem) {
                if (elem.izele_taxonomy === "Mammal") {
                    return elem
                }
            });
        },
        speciesList__fish: function (state) {
            return state.species.filter(function (elem) {
                if (elem.izele_taxonomy === "Ray-finned fish" || elem.izele_taxonomy === 'Cartilaginous fish') {
                    return elem
                }
            });
        },
        speciesList__birds: function (state) {
            return state.species.filter(function (elem) {
                if (elem.izele_taxonomy === "Bird") {
                    return elem
                }
            });
        },
        speciesList__plants: function (state) {
            return state.species.filter(function (elem) {
                if (elem.izele_taxonomy === "Plant") {
                    return elem
                }
            });
        },
        speciesList__others: function (state) {
            return state.species.filter(function (elem) {
                if (["Mammal", "Ray-finned fish", "Cartilaginous fish", "Bird", "Plant"].indexOf(elem.izele_taxonomy) === -1) {
                    return elem
                }
            });
        },
        speciesList__orderedBySciName: function (state) {
            return state.species.sort(function (a, b) {
                var nameA = a.scientific_name.toUpperCase();
                var nameB = b.scientific_name.toUpperCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
        }
    }
}