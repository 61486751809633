import Vue from 'vue';
import Vuex from 'vuex';
import notifications from './notification_store';
import documents from './document_store';
import organisation from './organisation_store';
import user_page from './user_page_store';
import user_messages from './user_message_store';
import species from './species_store';
import photos from "./photo_store";
import page from "./pages/page-store";
import pageSpatial from "./pages/page-store--spatial";
import places from "./places_store";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        organisation,
        page,
        pageSpatial,
        notifications,
        documents,
        user_page,
        user_messages,
        species,
        photos,
        places
    }
});
