/**
 * Example for adding a message, content supports html tags:
 this.$store.commit("user_messages/addMessage", {
     level: "error",
     title: this.gettext("A title"),
     content: this.gettext("Some content. <p>A <b>bold</b> paragraph</p>")
 });
 * */

import Vue from 'vue';
import store from "../store/_index";
import UserMessageOverlay from './user-message-overlay.vue';


izele.vueApps.messagesToUser = new Vue({
    el: '#messages-to-user',
    name: 'UserMessages',
    store: store,
    components: {
        UserMessageOverlay
    },
    data: {},
    methods: {
        messageClosed: function () {
            this.$store.commit("user_messages/deleteFirstMessage");
        }
    },
    computed: {
        mostRecentMessage: function () {
            return store.getters["user_messages/getMostRecentMessage"];
        }
    },
    watch: {},
    mounted: function () {
        if (izele.messages.length > 0) {
            this.$store.dispatch("user_messages/addTemplateInjectedMessages", izele.messages);
        }
    }
});