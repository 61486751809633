var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-notifications", staticStyle: { float: "left" } },
    [
      _c(
        "div",
        {
          staticClass: "dropdown-toggle",
          attrs: {
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "true",
          },
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "bell" } }),
          _vm._v(" "),
          _vm.liveNotifications > 0
            ? _c("div", { staticClass: "menu-notification-number" }, [
                _vm._v(_vm._s(_vm.liveNotifications)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu dropdown-menu-right",
          staticStyle: { width: "300px" },
        },
        [
          _vm._l(
            _vm.notifications.slice(0, _vm.$parent.maxNotifications),
            function (n) {
              return _vm.notifications.length > 0
                ? _c("MenuNotificationElement", {
                    key: n.id,
                    attrs: { notification: n },
                  })
                : _vm._e()
            }
          ),
          _vm._v(" "),
          !_vm.$store.state.notifications.loading &&
          _vm.notifications.length === 0
            ? _c("li", [_vm._v(_vm._s(_vm.gettext("No notifications found")))])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.notifications.loading
            ? _c("li", [_vm._v(_vm._s(_vm.gettext("Loading notifications")))])
            : _vm._e(),
          _vm._v(" "),
          _c("li", { staticClass: "divider", attrs: { role: "separator" } }),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: _vm.notificationUrl } }, [
              _vm._v(_vm._s(_vm.gettext("See all notifications"))),
            ]),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }