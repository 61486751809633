import {capitalizeFirstLetter} from "../tools/string";

import { icon } from "@fortawesome/fontawesome-svg-core";
import { faBan } from "@fortawesome/pro-light-svg-icons";


export function formatPageMarkerPopup(metadata, ecotourismActivities) {
    var popup = "<a class='map-icon-pages-popup' href='" + metadata.url + "'>" +
                "<img src='" + metadata.logo + "' alt=''>" +
                "<p>" +
                    "<span>" + metadata.name + "</span><br>" +
                    "<span>" + capitalizeFirstLetter(metadata.type) + "</span>" +
                "</p>" +
                "<div class='clearfix'></div>";

    if (ecotourismActivities && Array.isArray(ecotourismActivities) && ecotourismActivities.length > 0) {
        popup += "<div class='activities-for-popup'>";

        ecotourismActivities.forEach(function (activity) {
            // note: covid tooltip text not translated as can't use v-bind for some reason
            if (activity && activity.status === "closed_covid") {
                popup += "" +
                    "<div>" +
                        "<img src='/static/img/icons/activities/" + activity.activity_image + "' alt='" + activity.activity + "'>" +
                        "<div data-toggle='tooltip' data-placement='top' title='Currently unavailable due to COVID-19'>" +
                            icon(faBan).html +
                        "</div>" +
                    "</div>";

            } else {
                popup += "" +
                    "<div>" +
                        "<img src='/static/img/icons/activities/" + activity.activity_image + "' alt='" + activity.activity + "'>" +
                    "</div>";
            }
        });

        popup += "</div>"
    }

    return popup + "</a>";
}