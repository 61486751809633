/** Main notifications page - individual notification */

<template>
    <div v-bind:class="notificationLive">
        <!-- Message -->
        <div v-if="notification.notification_type === 'message'" class="notification rounded" v-bind:class="{'hover-box-shadow': notification.related_object}">
            <div>
                <div class="notification-controls" v-if="notification.notification_live">
                    <span v-on:click.stop.prevent="markMessageRead" data-toggle="tooltip" data-placement="left" v-bind:title="gettext('Mark as read')">
                        <font-awesome-icon icon="check-circle"/>
                    </span>
                </div>

                <h3>{{ notification.notification_title }}</h3>
                <p>{{ notification.notification_message }}</p>
                <p v-if="notification.related_object">
                    <a v-bind:href="notification.related_object">{{ notificationLinkType }}</a>
                </p>
                <h4>{{ notification.time_string }}</h4>
            </div>
        </div>

        <!-- Action required, link to the object if relevant -->
        <div v-if="notification.notification_type === 'action' || notification.notification_type === 'action_object'" v-bind:href="$parent.notificationUrl" class="notification rounded">
            <h3 v-if="notification.notification_type === 'action'">{{ notification.notification_title }}</h3>
            <!-- Wrap the notification in a link if there's a relevant object -->
            <a v-bind:href="notification.related_object" v-if="notification.notification_type === 'action_object'">
                <h3>{{ notification.notification_title }}</h3>
            </a>

            <p>{{ notification.notification_message }}</p>
            <h4>{{ notification.time_string }}</h4>
        </div>

    </div>
</template>

<script>
    import { library } from "@fortawesome/fontawesome-svg-core";
    import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
    library.add(faCheckCircle);


    export default {
        components: {},
        props: {
            'notification': {type: Object, required: true}
        },
        data: function () {
            return {}
        },
        methods: {
            markMessageRead: function () {
                // pass id to parent to modify element
                this.$emit('mark-read', this.notification.id);
            }
        },
        computed: {
            notificationLive: function () {
                return {
                    'menu-notification-viewed': !this.notification.notification_live
                };
            },
            notificationLinkType: function () {
                if (this.notification.notification_subtype === 'new_izele_post') {
                    return this.gettext("Link to post");
                } else if (this.notification.notification_subtype === 'new_comment') {
                    return this.gettext("Link to comment");
                }
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                $(this.$el).find('[data-toggle="tooltip"]').tooltip();
            })
        }
    }
</script>

<style lang="scss">
    .notification {
        border: 1px solid grey;
        margin: 0 0 8px 0;
        padding: 8px;

        h3 {
            margin: 0;
            font-size: 130%;
        }

        h4 {
            margin: 0;
            font-size: 100%;
            font-style: italic;
        }
    }

    .notification-controls {
        float: right;
        min-width: 20px;
        min-height: 20px;

        span {
            color: #006633;
            float: right;
            cursor: pointer;

            &:hover {
                color: #ffd900;
            }
        }

        svg {
            font-size: 120%;
        }
    }
</style>