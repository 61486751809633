import Vue from 'vue';
import GeneralSearch from '../search/global-search.vue';
import MenuNotificationDropdown from '../notifications/menu-notification-dropdown.vue';
import store from "../store/_index";
import Cookies from 'js-cookie';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faPlus, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faCogs, faIdCard } from "@fortawesome/pro-regular-svg-icons";
library.add(faUser, faIdCard, faCogs, faPlus, faSignOutAlt);


izele.vueApps.mainMenuVM = new Vue({
    el: '#main-nav',
    name: 'MainNav',
    store: store,
    components: {
        "GeneralSearch": GeneralSearch,
        "MenuNotificationDropdown": MenuNotificationDropdown
    },
    data: {
        maxNotifications: izele.maxNotificationsInMenu
    },
    methods: {
        update: function (updateType) {
            this.$store.dispatch('updateNotifications', {updateType: updateType});
        },
        logout: function () {
            $.ajax({
                url: "/user/logout/",
                method: "POST",
                data: {
                    csrfmiddlewaretoken: Cookies.get('csrftoken'),
                    post_origin: 'ajax'
                },
                success: function () {
                    window.setTimeout(function () {
                        window.location.reload();
                    }, 200);

                }
            });
        }
    },
    computed: {
        notifications: function () {
            return store.state.notifications.notifications
        },
        subsetPagesAdminFor: function () {
            return this.$store.getters["user_page/subsetPagesAdminFor"]
        }
    },
    mounted: function () {
        // notifications
        this.update('get_initial');

        if (!izele.debug) {
            window.setInterval(function () {
                this.update('get_newer');
            }.bind(this), 60000);
        }

        // store updates
        this.$store.commit("user_page/initialisePagesAdminFor")
    }
});
