import Vue from 'vue';


export default {
    namespaced: true,
    state: {
        initialDataLoadComplete: false,
        country_lives_in: undefined,
        geoname_location: undefined,
        pages_admin_for: [],
        countries: [],
        pagesVisited: [],
        pagesLiked: [],
        usersFollowed: [],
        externalLinks: [],
        pageContacts: []
    },
    mutations: {
        addInitialData: function (state, data) {
            state.countries = data.countries;
            state.country_lives_in = data.country_lives_in;
            state.geoname_location = data.geoname_location;
            state.pagesVisited = data.visited_pages;
            state.pagesLiked = data.favourite_pages;
            state.usersFollowed = data.users_followed;
            state.externalLinks = data.external_links;
            state.pageContacts = data.page_contacts;

            state.initialDataLoadComplete = true;
        },
        initialisePagesAdminFor: function(state) {
            state.pages_admin_for = izele.userData.isAdminFor;
        },
        removeVisitedPage: function (state, pageId) {
            state.pagesVisited = state.pagesVisited.filter(function (e) {
                return e.id !== pageId
            })
        },
        removeLikedPage: function (state, pageId) {
            state.pagesLiked = state.pagesLiked.filter(function (e) {
                return e.id !== pageId
            })
        },
        removeFollowedUser: function (state, userId) {
            state.usersFollowed = state.usersFollowed.filter(function (e) {
                return e.id !== userId
            })
        },
        addCountry: function (state, countryData) {
            state.countries.push(countryData);
        },
        removeCountry: function (state, countryId) {
            state.countries = state.countries.filter(function (e) {
                return e.iso_country_code !== countryId
            })
        },
        addContact: function (state, contactData) {
            state.pageContacts.push(contactData);
        },
        removeContact: function (state, contactId) {
            state.pageContacts = state.pageContacts.filter(function (e) {
                return e.id !== contactId
            })
        },
        highlightUserContact: function (state, contactData) {
            for (var i=0; i < state.pageContacts.length; i++) {
                if (state.pageContacts[i].id === contactData.id) {
                    state.pageContacts[i].is_primary = contactData.state;
                    break;
                }
            }
        },
        updateContact: function (state, contactData) {
            for (var i=0; i < state.pageContacts.length; i++) {
                if (state.pageContacts[i].id === contactData.id) {
                    state.pageContacts[i].contact = contactData.contact;
                    break;
                }
            }
        },
        addExternalLink: function (state, linkData) {
            state.externalLinks.push(linkData)
        },
        removeExternalLink: function (state, linkId) {
            state.externalLinks = state.externalLinks.filter(function (e) {
                return e.id !== linkId
            })
        },
        highlightUserLink: function (state, linkData) {
            for (var i=0; i < state.externalLinks.length; i++) {
                if (state.externalLinks[i].id === linkData.id) {
                    state.externalLinks[i].is_primary = linkData.state;
                    break;
                }
            }
        },
        updateExternalLink: function (state, linkData) {
            for (var i=0; i < state.externalLinks.length; i++) {
                if (state.externalLinks[i].id === linkData.id) {
                    state.externalLinks[i].link = linkData.external_link;
                    break;
                }
            }
        },
        updateExternalLinkText: function (state, linkData) {
            for (var i=0; i < state.externalLinks.length; i++) {
                if (state.externalLinks[i].id === linkData.id) {
                    state.externalLinks[i].link_text = linkData.external_link_text;
                    break;
                }
            }
        },
        updateCountryLivedIn: function (state, countryData) {
            countryData.flag = "/static/img/flags/" + countryData.flag;
            state.country_lives_in = countryData;
        },
        updateGeonameLocation: function (state, geonameData) {
            state.geoname_location = {
                geoname_id: geonameData.geonameId,
                name: geonameData.name,
                toponym_name: geonameData.toponymName
            }
        }
    },
    actions: {
        addUserInitialData: function (context, data) {
            context.commit('addInitialData', data);
        },
    },
    getters: {
        subsetPagesAdminFor: function (state) {
            return state.pages_admin_for.slice(0, 5)
        },
        visitedPages: function (state) { return state.pagesVisited; },
        likedPages: function (state) { return state.pagesLiked; },
        usersFollowed: function (state) { return state.usersFollowed; },
        countries: function (state) { return state.countries; },
        orderedExternalLinks: function (state) {
            var links = [];

            state.externalLinks.forEach(function (link) {
                if (link.is_primary) {
                    links.unshift(link);
                } else {
                    links.push(link);
                }
            });

            return links;
        },
        highlightedUserLinks: function (state) {
            var links = [];

            state.externalLinks.forEach(function (link) {
                if (link.is_primary) {
                    links.push(link);
                }
            });

            return links;
        },
        orderedPageContacts: function (state) {
            var contacts = [];

            state.pageContacts.forEach(function (contact) {
                if (contact.is_primary) {
                    contacts.unshift(contact);
                } else {
                    contacts.push(contact);
                }
            });

            return contacts;
        },
        highlightedContacts: function (state) {
            var contacts = [];

            state.pageContacts.forEach(function (contact) {
                if (contact.is_primary) {
                    contacts.push(contact);
                }
            });

            return contacts;
        },
        organisationsAdminFor: function (state) {
            var pages = [];

            state.pages_admin_for.forEach(function (page) {
                if (page.type === "organisation") {
                    pages.push(page);
                }
            });

            return pages;
        },
        projectsAdminFor: function (state) {
            var pages = [];

            state.pages_admin_for.forEach(function (page) {
                if (page.type === "project") {
                    pages.push(page);
                }
            });

            return pages;
        }
    }
}