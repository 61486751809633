/**
General search box
todo: catch escape button and close search results
*/

<template>
    <div class="general-search form-group-sm" v-bind:class="{'general-search-frontpage': isFrontpage}">
        <form autocomplete="off"><!-- this: v-model="searchText" not used, as composition ignored on android (requires space during search) -->
            <input class="form-control"
                   v-bind:title="placeholder"
                   v-bind:placeholder="placeholder"
                   v-on:input="searchText = $event.target.value"
                   v-on:keyup.up.prevent="changeSelected('up')"
                   v-on:keyup.down.prevent="changeSelected('down')"
                   v-on:keypress.enter.prevent="changePage"
                   v-on:focus="showResultsAndScrollPage($event)"
                   v-on:blur="hideResultsAfterRender"/>
        </form>


        <div class="search-results" v-if="showResults">
            <div>
                <result-element
                        v-for="result in results"
                        v-bind:key="result.id"
                        v-bind:result="result"
                        v-on:result-clicked="resultClicked">
                </result-element>

                <div v-if="results.length === 0 && searchText.length > 0 && !firstLoad"><i>{{ gettext("No results found") }}</i></div>

                <div class="sr-pre-msg" v-if="results.length === 0 && searchText.length === 0"><i>{{ placeholder }}</i></div>

                <div class="sr-searching loading-ellipsis" v-if="firstLoad && searchText.length > 0">{{ gettext("Loading results...") }}</div>

                <div class="text-danger" v-if="searchError">{{ searchError }}</div>
            </div>

            <!--<a class="search-results-more" v-bind:href="allResultsLink">-->
                <!--See all results-->
            <!--</a>-->
        </div>
    </div>
</template>

<script>
    import ResultElement from "./global-search--element.vue";

    export default {
        components: {
            "ResultElement": ResultElement
        },
        props: {
            isFrontpage: {type: Boolean, default: false}
        },
        data: function () {
            return {
                searchText: "",
                placeholder: this.gettext("Search for a page..."),
                firstLoad: true,  // only show loading message when first results loading
                searchError: "",
                results: [],
                showResults: false,
                resultSelected: null
            }
        },
        methods: {
            // gettext: function(text) {return gettext(text)},
            search: function () {
                if (this.searchText.length > 0) {
                    $.ajax({
                        url: izele.urls.globalSearch ,
                        method: 'GET',
                        context: this,
                        data: {
                            search: this.searchText
                        }
                    }).done(function(data) {
                        // add a selected modifier to each record to allow keyboard use
                        data.forEach(function (item, index) {
                            data[index] = this.formatText(item);
                        }, this);

                        this.results = data;

                    }).fail(function(xhr) {
                        this.searchError = this.gettext("Error getting results") + " (" + xhr.status + ")"
                    }).always(function () {
                        this.firstLoad = false;
                    });

                } else {
                    this.results = [];
                }


            },
            formatText: function (result) {
                result['selected'] = false;

                // todo: add search highlighting?

                return result;
            },
            changeSelected: function (direction) {
                var selectedFound = false, newPosition = 0;
                var arrayCopy = this.results;

                for (var i = 0; i < arrayCopy.length; i++) {

                    if (arrayCopy[i].selected) {
                        selectedFound = true;
                        arrayCopy[i].selected = false;

                        if (direction === 'up') {
                            if (i - 1 < 0) {
                                newPosition = arrayCopy.length - 1;
                            } else {
                                newPosition = i - 1;
                            }

                        } else {
                            if (i + 1 < arrayCopy.length) {
                                newPosition = i + 1;
                            }
                        }

                        arrayCopy[newPosition].selected = true;
                        this.resultSelected = newPosition;
                        break;
                    }
                }

                if (!selectedFound) {
                    newPosition = 0;
                    if (direction === 'up') {
                        newPosition = arrayCopy.length - 1;
                    }

                    arrayCopy[newPosition].selected = true;
                    this.resultSelected = newPosition;

                }

                this.results = arrayCopy;
                this.adjustScroll();

            },
            resultClicked: function (result) {
                var arrayCopy = this.results;

                for (var i = 0; i < arrayCopy.length; i++) {
                    arrayCopy[i].selected = false;

                    if (arrayCopy[i].id === result[0] && arrayCopy[i].type === result[1]) {
                        arrayCopy[i].selected = true;
                        this.resultSelected = i;
                    }
                }

                this.$nextTick(this.changePage);

            },
            changePage: function () {
                // either enter key or clicked
                var selectedResult = this.results[this.resultSelected];
                this.searchText = '';
                var url = '/';

                switch (selectedResult.type) {
                    case 'organisation':
                        url += selectedResult.id + '/' + selectedResult.slug + '/';
                        break;

                    case 'project':
                        url += 'projects/' + selectedResult.id + '/' + selectedResult.slug + '/';
                        break;

                    case 'person':
                        url += "user/" + selectedResult.id + "/" + selectedResult.slug + "/";
                        break;

                    // case 'species':
                    //     break;

                    case "country":
                        url += "c/" + selectedResult.slug + "/feed/";
                }

                this.showResults = false;
                this.$nextTick(function () {
                    window.location.href = url;
                });
            },
            showResultsAndScrollPage: function (event) {
                this.showResults = true;

                // scroll the page to show the results if on a smaller screen
                if (this.isFrontpage) {
                    var windowElem = $(window);
                    var inputElement = $(event.target);

                    if (windowElem.width() < 768) {
                        $('html, body').animate({
                            scrollTop: inputElement.offset().top - 39
                        }, 400)

                    } else if (windowElem.width() < 992) {
                        var relativePos = inputElement.offset().top - windowElem.scrollTop();

                        if (relativePos > windowElem.height() - 425) {
                            $('html, body').animate({
                                scrollTop: inputElement.offset().top - 39
                            }, 400)
                        }

                    }
                }
            },
            adjustScroll: function () {
                this.$nextTick(function () {
                    $(this.$el).find('.sre-selected')[0].scrollIntoView(false);
                });
            },
            hideResultsAfterRender: function () {
                // delay to allow click to propagate into list of results
                this.$nextTick(function () {
                    window.setTimeout(function () {
                        this.showResults = false;
                    }.bind(this), 500);
                });
            }
        },
        computed: {
            // allResultsLink: function () {
            //     console.log('encoding', encodeURIComponent(this.searchText));
            //     return '/search/' + 'search=' + encodeURIComponent(this.searchText);
            // }
        },
        watch: {
            searchText: izele.tools.debounce(500, function () {
                if (this.searchText.length > 0) {
                    this.search();

                } else {
                    this.firstLoad = true;
                    this.results = [];
                }

            })
        }
    }
</script>

<style lang="scss">
    @import "../../styles/variables";
    @import "../../styles/common_styles.scss";

    .general-search-frontpage {
        // change the search bar position on the frontpage for small screens
        @media screen and (max-width: 395px) {
            position: absolute;
            width: 90%;
            margin: -56px 0 0 10px;

            form {
                input {
                    position: relative;
                    width: 100%;
                    font-size: 100% !important;
                }
            }
        }
    }

    .general-search {
        width: 350px;

        @media #{$bootstrap-break-sm} {
            width: 280px;
        }

        input {
            @media #{$bootstrap-break-sm} {
                width: 280px;
                padding: 2px 0 !important;
                height: 22px !important;
            }

            @media screen and (max-width: 395px) {
                width: 215px;
                font-size: 70% !important;
            }
        }

        .search-results {
            position: absolute;
            background-color: white;
            border-radius: 0 0 $izele-border-radius $izele-border-radius;
            border: 1px solid $izele-green;
            width: 400px;

            & > div:first-child {
                padding: 5px 5px 0 5px;

                max-height: 400px;
                overflow-y: scroll;
            }

            .search-results-more {
                display: block;
                background-color: $izele-green;
                color: white;
                padding: 1px 5px;
                border-radius: 0 0 $izele-border-radius $izele-border-radius;

                &:hover {
                    text-decoration: none;
                    color: $izele-yellow;
                }
            }

            @media #{$bootstrap-break-xs} {
                width: 275px;

                .sr-pre-msg, .sr-loading {
                    font-size: 80%;
                    // below is to force message alignment
                    position: relative;
                    top: -3px;
                }

            }
        }
    }
</style>