import Vue from 'vue';
import NotificationElement from './notification-element.vue';
import store from '../store/_index';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSync } from "@fortawesome/free-solid-svg-icons";
library.add(faSync);


(izele.vueApps.notifications = function (notifications, $, Vue, undefined) {
    /** Main notification page VM */
    notifications.notificationManager = function () {
        notifications.notificationManagerVM = new Vue({
            el: '#notification-root',
            store,
            components: {
                NotificationElement
            },
            data: {
                allowScrollTrigger: true
            },
            methods: {
                isEmptySpace: function () {
                    var element = $(this.$el);
                    return (element.height() + element.offset().top < window.innerHeight);
                },
                update: function (updateType) {  // updateType options: get_initial, get_older, get_newer
                    var vueInstance = this;  // doing this with bind, rather than variable fails strangely on nexttick

                    this.$store.dispatch('updateNotifications', {updateType: updateType}).then(function () {
                        // On return (action returns promise), get some more if space. Without timeout, race condition spawns new updates faster than browser knows what to do with
                        window.setTimeout(function () {
                            vueInstance.$nextTick(function () {
                                if (vueInstance.isEmptySpace() === true) {
                                    vueInstance.update('get_older');
                                }
                            });
                        }, 500);

                    });
                },
                handleScroll: function () {
                    if (store.state.notifications.olderNotificationsAvailable === true) {
                        if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
                            if (this.allowScrollTrigger === true) {
                                this.allowScrollTrigger = false;

                                this.$nextTick(function () {
                                    this.update('get_older');

                                    var instance = this;
                                    window.setTimeout(function () {
                                        instance.allowScrollTrigger = true;
                                    }, 1000);

                                });
                            }
                        }
                    }

                },
                markRead: function (id) {
                    this.$store.dispatch('markAsRead', id);
                }
            },
            computed: {
                notifications: function () {
                    return store.state.notifications.notifications
                }
            },
            mounted: function () {
                this.$nextTick(function () {
                    // wait until first load complete, then add some more
                    var vueInstance = this;
                    var intervalHandle = window.setInterval(function () {
                        if (store.state.notifications.isInitialLoadComplete === true) {
                            if (vueInstance.isEmptySpace()) {
                                vueInstance.update('get_older');
                            }
                            clearInterval(intervalHandle);
                        }
                    }, 100);

                    window.addEventListener('scroll', this.handleScroll);
                    window.addEventListener('DOMMouseScroll', this.handleScroll);  // scrolling at end of (non-moving) page: firefox
                    window.addEventListener('mousewheel', this.handleScroll);  // scrolling at end of (non-moving) page: chrome
                });
            }
        });

    };


})(izele.vueApps.notifications = izele.vueApps.notifications || {}, jQuery, Vue);