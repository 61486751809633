var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.notificationLive }, [
    _vm.notification.notification_type === "message"
      ? _c(
          "div",
          {
            staticClass: "notification rounded",
            class: { "hover-box-shadow": _vm.notification.related_object },
          },
          [
            _c("div", [
              _vm.notification.notification_live
                ? _c("div", { staticClass: "notification-controls" }, [
                    _c(
                      "span",
                      {
                        attrs: {
                          "data-toggle": "tooltip",
                          "data-placement": "left",
                          title: _vm.gettext("Mark as read"),
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.markMessageRead.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "check-circle" },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.notification.notification_title))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.notification.notification_message))]),
              _vm._v(" "),
              _vm.notification.related_object
                ? _c("p", [
                    _c(
                      "a",
                      { attrs: { href: _vm.notification.related_object } },
                      [_vm._v(_vm._s(_vm.notificationLinkType))]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("h4", [_vm._v(_vm._s(_vm.notification.time_string))]),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.notification.notification_type === "action" ||
    _vm.notification.notification_type === "action_object"
      ? _c(
          "div",
          {
            staticClass: "notification rounded",
            attrs: { href: _vm.$parent.notificationUrl },
          },
          [
            _vm.notification.notification_type === "action"
              ? _c("h3", [_vm._v(_vm._s(_vm.notification.notification_title))])
              : _vm._e(),
            _vm._v(" "),
            _vm.notification.notification_type === "action_object"
              ? _c("a", { attrs: { href: _vm.notification.related_object } }, [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.notification.notification_title)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.notification.notification_message))]),
            _vm._v(" "),
            _c("h4", [_vm._v(_vm._s(_vm.notification.time_string))]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }