// noinspection JSUnusedGlobalSymbols
export default {
    namespaced: true,
    state: {
        availablePhotos: {
            photos: [],
            update_url: undefined,
            load_in_progress: false,
            initial_load_complete: false,
            older_photos_available: true,
            errors: ""
        }

    },
    mutations: {
        set_update_url: function (state, url) {
            state.availablePhotos.update_url = url;
        },
        set_error_text: function (state, text) {
            state.availablePhotos.errors = text;
        },
        toggle_load: function (state, value) {
            state.availablePhotos.load_in_progress = value;
        },
        add_available_photos: function (state, photos) {
            photos.forEach(function (photo) {
                state.availablePhotos.photos.push(photo)
            });
        }
    },
    actions: {
        managerShown: function (context) {
            if (!context.state.availablePhotos.load_in_progress) {
                if (!context.state.availablePhotos.initial_load_complete) {
                    context.dispatch("availablePhotosInitialLoad")
                } else {
                    context.dispatch("availablePhotosGetNewer")
                }
            }
        },
        availablePhotosInitialLoad: function (context) {
            if (!context.state.availablePhotos.update_url) {
                throw Error("IzeleMessage: photo_store missing update url");
            }

            context.dispatch("getPhotos", "initial").then(function () {
                context.state.availablePhotos.initial_load_complete = true;
            });

        },
        availablePhotosGetOlder: function (context) {
            if (context.state.availablePhotos.older_photos_available) {
                context.dispatch("getPhotos", "older")
            }
        },
        availablePhotosGetNewer: function (context) {
            context.dispatch("getPhotos", "newer")
        },
        getPhotos: function (context, updateType) {
            context.commit("set_error_text", "");
            context.commit("toggle_load", true);

            var ajax_data = {
                request_type: updateType
            };

            if (updateType === "older") {
                ajax_data["timestamp"] = context.getters.orderedAvailablePhotos[context.getters.orderedAvailablePhotos.length - 1].timestamp;
            } else if (updateType === "newer") {
                ajax_data["timestamp"] = context.getters.orderedAvailablePhotos[0].timestamp;
            }

            $.ajax({
                url: context.state.availablePhotos.update_url,
                method: 'GET',
                context: this,
                data: ajax_data

            }).done(function(data) {
                if (data.length > 0) {
                    context.commit("add_available_photos", data)
                } else {
                    if (updateType !== "newer") {
                        context.state.availablePhotos.older_photos_available = false;
                        console.log('no more older photos');
                    }
                }

            }).fail(function(xhr) {
                context.commit("set_error_text", izele.tools.formatXHRResponse(xhr, "GET"));
            }).always(function () {
                context.commit("toggle_load", false);
            });
        }
    },
    getters: {
        orderedAvailablePhotos: function (state) {
            return state.availablePhotos.photos.sort(function (a, b) {
                if (a.timestamp > b.timestamp) {
                    return -1
                } else if (a.timestamp < b.timestamp) {
                    return 1
                } else {
                    return 0
                }
            });
        }
    }
}