export default {
    state: {
        notifications: [],
        total: 0,
        totalLive: 0,
        newestLoaded: 0,
        oldestLoaded: 0,
        olderNotificationsAvailable: true,
        loading: false,
        error: '',
        isInitialLoadComplete: false
    },
    mutations: {
        updateTotalNotifications: function (state, newTotal) {
            state.total = newTotal;
        },
        updateTotalLiveNotifications: function (state, newTotal) {
            state.totalLive = newTotal;
        },
        incDecLiveNotifications: function (state, increment) {
            state.totalLive += increment;
        },
        updateNewestLoaded: function (state, newId) {
            state.newestLoaded = newId;
        },
        updateOldestLoaded: function (state, newId) {
            state.oldestLoaded = newId;
        },
        updateIsInitialComplete: function (state, value) {
            state.isInitialLoadComplete = value;
        },
        updateOlderNotificationsAvailable: function (state, value) {
            state.olderNotificationsAvailable = value;
        },
        appendNotification: function (state, notification) {
            state.notifications.push(notification);
        },
        prependNotification: function (state, notification) {
            state.notifications.unshift(notification);
        },
        markNotificationAsRead: function (state, id) {
            state.notifications.forEach(function (notification) {
                if (notification.id === id) {
                    notification.notification_live = false;
                }
            });
        }
    },
    actions: {
        updateNotifications: function(context, data) {
            if (izele.userData.loggedIn && context.state.loading !== true && (data.updateType === 'get_newer' || context.state.olderNotificationsAvailable === true)) {
                context.state.loading = true;

                $.ajax({
                    url: izele.urls.notifications_api,
                    method: 'GET',
                    context: this,
                    data: {
                        newest_loaded: context.state.newestLoaded,
                        oldest_loaded: context.state.oldestLoaded,
                        update_type: data.updateType
                    }

                }).done(function(result) {
                    context.commit('updateTotalNotifications', result.count);
                    context.commit('updateTotalLiveNotifications', result.live_count);

                    // Update min/max notification ids
                    var arrayOfIds = result.results.map(function (e) {
                        return e.id;
                    });
                    var minId = Math.min.apply(Math, arrayOfIds);
                    var maxId = Math.max.apply(Math, arrayOfIds);
                    if (maxId > context.state.newestLoaded) {
                        context.commit('updateNewestLoaded', maxId);
                    }
                    if (minId < context.state.oldestLoaded || data.updateType === 'get_initial') {
                        context.commit('updateOldestLoaded', minId);
                    }

                    // Append/prepend new notifications
                    result.results.forEach(function(element) {
                        if (data.updateType === 'get_initial' || data.updateType === 'get_older') {
                            context.commit('appendNotification', element);
                        } else if (data.updateType === 'get_newer') {
                            context.commit('prependNotification', element);
                        }
                    });

                    if (data.updateType === 'get_initial') {
                        context.commit('updateIsInitialComplete', true);
                    }

                    if ((data.updateType === 'get_older' || data.updateType === 'get_initial') && result.count === 0) {
                        context.commit('updateOlderNotificationsAvailable', false);
                    }

                }).fail(function(xhr) {
                    context.state.error = izele.tools.formatXHRResponse(xhr, 'GET');
                }).always(function () {
                    context.state.loading = false;
                });
            }
        },
        markAsRead: function (context, id) {
            $.ajax({
                url: izele.urls.notifications_api + id + '/',
                method: 'PATCH',
                context: this,
                data: {
                    action: "mark-read"
                }

            }).done(function() {
                context.commit('markNotificationAsRead', id);
                context.commit('incDecLiveNotifications', -1);

            }).fail(function(xhr) {
                // todo: add message?
            });
        }
    }
}