// todo : lots of duplication with page-store--spatial re: activities
import L from "leaflet";
import Fuse from "fuse.js";
import { addToSentry } from "../../js/tools/general";
import {GetPageWithActivitiesObject, SurroundingSpatialObject} from "./pages/page-store--spatial--tools";


function returnIntersectionsOfSearchedArrays(filteredByMap, filteredByText, filteredByActivity) {
    var mapAndText = filteredByMap.filter(function (mapArrayPage) {
        return filteredByText.filter(function (textArrayPage) {
            return mapArrayPage.page_id === textArrayPage.page_id;
        }).length > 0;
    });

    return mapAndText.filter(function (mapAndTextPage) {
        return filteredByActivity.filter(function (activityArrayPage) {
            return mapAndTextPage.page_id === activityArrayPage.page_id;
        }).length > 0;
    });
}

// noinspection JSUnresolvedFunction
export default {
    namespaced: true,
    state: {
        pages: [],
        searchText: [],
        pagesFilteredByMapChange: [],
        pagesFilteredByTextSearch: [],
        pagesFilteredByActivitySearch: [],
        fuseInstance: null,
        onlyShowMappedPages: true,
        mapReference: null,
        mapLayers: {
            pages: L.markerClusterGroup({maxClusterRadius: 40}),
            pagesWithActivities: L.markerClusterGroup({maxClusterRadius: 40})
        },
        activitiesSearched: []
    },
    mutations: {
        storeInitialPageData: function (state, spatialObject) {
            state.pages.push(spatialObject);
            if(spatialObject.layer) {
                state.mapLayers.pages.addLayer(spatialObject.layer);
            }
        },
        setMapReference: function (state, mapRef) {
            state.mapReference = mapRef;

            // enable bootstrap tooltips in popups
            state.mapReference.on("popupopen", function (e) {
                $(e.target._container).find('[data-toggle="tooltip"]').tooltip({html: true, container: "body"});
            })
        },

        setPagesFilteredByMapChange: function (state, pageArray) {
            state.pagesFilteredByMapChange = pageArray;
        },
        setPagesFilteredBySearch: function (state, pageArray) {
            state.pagesFilteredByTextSearch = pageArray;
        },
        setPagesFilteredByActivity: function (state, pageArray) {
            state.pagesFilteredByActivitySearch = pageArray;
        },

        setSearchText: function (state, searchText) {
            state.searchText = searchText;
        },

        updatePagesWithActivities: function (state, pageObject) {
            for (var i=0; i < state.pages.length; i++) {
                if (state.pages[i].page_id === pageObject.id) {
                    state.pages[i].activities = pageObject.activities;
                    break;
                }
            }
        },
        addActivityToSearched: function (state, activityObject) {
            state.activitiesSearched.push(activityObject);
        },
        removeActivityFromSearched: function (state, activityId) {
            state.activitiesSearched = state.activitiesSearched.filter(function (e) {
                return e.id !== activityId
            });
        },
        enableActivitySearchLayer: function (state) {
            state.mapLayers.pages.remove();
            state.mapLayers.pagesWithActivities.addTo(state.mapReference);
        },
        disableActivitySearchLayer: function (state) {
            state.mapLayers.pagesWithActivities.remove();
            state.mapLayers.pages.addTo(state.mapReference);
        }
    },
    actions: {
        addPages: function (context, data) {
            try {
                data.forEach(function (dataObject) {
                    context.commit("storeInitialPageData", new SurroundingSpatialObject(dataObject, true))
                });
            } catch (e) {
                addToSentry(e);
            }

            context.state.mapLayers.pages.addTo(context.state.mapReference);
            context.commit("setPagesFilteredByMapChange", context.state.pages);  // start full, so intersection in updateVisibleMarkers doesn't return empty array
            context.commit("setPagesFilteredByActivity", context.state.pages);  // also start full
            context.dispatch("updateFilterByTextSearch");
            context.dispatch("updateFilterFromMapChange");
        },
        updateOnlyShowMappedPages: function (context, showPages) {
            context.state.onlyShowMappedPages = showPages;
            context.dispatch("updateFilterFromMapChange");
        },
        setSearchTextAndSearch: function (context, searchText) {
            context.commit("setSearchText", searchText);
            context.dispatch("updateFilterByTextSearch");
        },
        updateFilterFromMapChange: function (context) {
            if (!context.state.onlyShowMappedPages) {
                context.commit("setPagesFilteredByMapChange", context.state.pages);
                return;
            }

            var updatedPageList = [];

            context.state.pages.forEach(function (page) {
                if (page.centroid && page.layer) {
                    if (context.state.mapReference.getBounds().contains(L.GeoJSON.coordsToLatLng(page.centroid.coordinates))) {
                        updatedPageList.push(page);
                    }
                }
            });

            context.commit("setPagesFilteredByMapChange", updatedPageList);
            context.dispatch("updateVisibleMarkers");
        },
        updateFilterByTextSearch: function (context) {
            if (!context.state.searchText || context.state.searchText.length === 0) {
                context.commit("setPagesFilteredBySearch", context.state.pages);

            } else {
                if (!context.fuseInstance) {
                    // this is set on the full load of pages to be compared in the filtered getter
                    // is it better to recreate the fuse instance each time in that function?
                    context.fuseInstance = new Fuse(context.state.pages, {
                        shouldSort: true, findAllMatches: true,
                        threshold: 0.3,
                        location: 0,
                        distance: 100,
                        maxPatternLength: 32,
                        minMatchCharLength: 1,
                        keys: ["acronym","page_name", "type", "description"]
                    });
                }

                context.commit("setPagesFilteredBySearch", context.fuseInstance.search(context.state.searchText));
            }

            context.dispatch("updateVisibleMarkers");
        },
        updateVisibleMarkers: function (context) {
            if (context.state.mapReference.hasLayer(context.state.mapLayers.pages)) {
                var availablePages = returnIntersectionsOfSearchedArrays(context.state.pagesFilteredByTextSearch,
                                                                         context.state.pagesFilteredByMapChange,
                                                                         context.state.pagesFilteredByActivitySearch);
                context.state.mapLayers.pages.clearLayers();

                availablePages.forEach(function (elem) {
                    if(elem.layer) {
                        context.state.mapLayers.pages.addLayer(elem.layer);
                    }
                });

            } else if (context.state.mapReference.hasLayer(context.state.mapLayers.pagesWithActivities)) {
                context.dispatch("updateActivitiesLayer");
            }
        },
        updateLayersWithActivities: function (context, data) {
            try {
                data[0].forEach(function (pageObject) {
                    pageObject.show = true;
                    context.commit("updatePagesWithActivities", pageObject);
                });

            } catch (e) {
                addToSentry(e);
            }
        },
        addActivityToSearchedAndUpdate: function (context, activity) {
            context.commit("addActivityToSearched", activity);
            context.dispatch("switchMarkerLayers").then(function () {
                context.dispatch("updateActivitiesLayer");
            });
        },
        removeActivityFromSearchedAndUpdate: function (context, activityId) {
            context.commit("removeActivityFromSearched", activityId);
            context.dispatch("switchMarkerLayers").then(function () {
                context.dispatch("updateActivitiesLayer");
            });
        },
        switchMarkerLayers: function (context) {
            if (context.state.activitiesSearched.length > 0) {
                if (!context.state.mapReference.hasLayer(context.state.mapLayers.pagesWithActivities)) {
                    context.commit("enableActivitySearchLayer");
                }

            } else {
                context.commit("disableActivitySearchLayer");
            }
        },
        updateActivitiesLayer: function (context) {
            context.state.mapLayers.pagesWithActivities.clearLayers();

            if (context.state.activitiesSearched.length > 0) {
                var matchingPages = [];

                context.state.pages.forEach(function (page) {
                    if (page.activities.length > 0) {
                        var matchingActivities = context.state.activitiesSearched.filter(function (activity) {
                            var match = false;
                            page.activities.forEach(function (pageActivity) {
                                if (pageActivity.id === activity.id) {
                                    match = true
                                }
                            })
                            return match
                            // return page.activities.includes(activity.id);
                        })

                        if (matchingActivities.length > 0) {
                            matchingPages.push(page);
                        }
                    }
                });

                context.commit("setPagesFilteredByActivity", matchingPages);

                var availablePages = returnIntersectionsOfSearchedArrays(context.state.pagesFilteredByTextSearch,
                                                                         context.state.pagesFilteredByMapChange,
                                                                         context.state.pagesFilteredByActivitySearch);

                availablePages.forEach(function(page) {
                    var activitiesObject = GetPageWithActivitiesObject(page, context.state.activitiesSearched, context.rootState.page.allActivities, false);
                    if (activitiesObject) {
                        context.state.mapLayers.pagesWithActivities.addLayer(activitiesObject);
                    }
                });

            } else {
                // no activities, return full possible set of pages
                context.commit("setPagesFilteredByActivity", context.state.pages);
            }
        }
    },
    getters: {
        filteredPages: function (state) {
            return returnIntersectionsOfSearchedArrays(state.pagesFilteredByTextSearch,
                                                       state.pagesFilteredByMapChange,
                                                       state.pagesFilteredByActivitySearch);
        },
        activitiesSearchedArray: function (state) {
            return state.activitiesSearched;
        },
    }
}