export default {
    namespaced: true,
    state: {
        amenitiesDataLoaded: false,
        tripadvisor_link: undefined,

        accommodation: {raw_text: "", html: "", photos: []},
        food: {raw_text: "", html: "", photos: []},
        shopping: {raw_text: "", html: "", photos: []},
        activities: {raw_text: "", html: "", available_activities: [], selected_activities: [], photos: []},
        covid: {raw_text: "", html: ""}
    },
    mutations: {
        addAmenitiesData: function (state, data) {
            state.accommodation.raw_text = data.accommodation_raw;
            state.accommodation.html = data.accommodation_html;
            state.food.raw_text = data.food_raw;
            state.food.html = data.food_html;
            state.activities.raw_text = data.activities_raw;
            state.activities.html = data.activities_html;
            state.shopping.raw_text = data.shopping_raw;
            state.shopping.html = data.shopping_html;

            state.activities.available_activities = data.available_activities;
            state.activities.selected_activities = data.selected_activities;

            state.accommodation.photos = data.accommodation_images;
            state.food.photos = data.food_images;
            state.activities.photos = data.activities_images;
            state.shopping.photos = data.shopping_images;

            state.covid.raw_text = data.covid_raw;
            state.covid.html = data.covid_html;

            state.amenitiesDataLoaded = true;
        },
        updateTripAdvisorLink: function (state, link) {
            state.tripadvisor_link = link;
        },
        updateAmenitiesText: function (state, newText) {
            switch (newText.amenities_section) {
                case "accommodation":
                    state.accommodation.raw_text = newText.content.raw_text;
                    state.accommodation.html = newText.content.html;
                    break;

                case "food":
                    state.food.raw_text = newText.content.raw_text;
                    state.food.html = newText.content.html;
                    break;

                case "activities":
                    state.activities.raw_text = newText.content.raw_text;
                    state.activities.html = newText.content.html;
                    break;

                case "shopping":
                    state.shopping.raw_text = newText.content.raw_text;
                    state.shopping.html = newText.content.html;
                    break;
            }
        },
        addSelectedActivity: function (state, activityObject) {
            state.activities.selected_activities.push(activityObject)
        },
        removeSelectedActivity: function (state, id) {
            state.activities.selected_activities = state.activities.selected_activities.filter(function (e) {
                return e.id !== id
            })
        },
        removeSelectedPhoto: function (state, photoData) {
            switch (photoData.amenities_section) {
                case "accommodation":
                    state.accommodation.photos = state.accommodation.photos.filter(function (e) {
                        return e.id !== photoData.id
                    });
                    break;
                case "food":
                    state.food.photos = state.food.photos.filter(function (e) {
                        return e.id !== photoData.id
                    });
                    break;
                case "activities":
                    state.activities.photos = state.activities.photos.filter(function (e) {
                        return e.id !== photoData.id
                    });
                    break;
                case "shopping":
                    state.shopping.photos = state.shopping.photos.filter(function (e) {
                        return e.id !== photoData.id
                    });
                    break;
            }
        },
        addSelectedPhoto: function (state, photoData) {
            switch (photoData.amenities_section) {
                case "accommodation":
                    state.accommodation.photos.push(photoData.photoData);
                    break;
                case "food":
                    state.food.photos.push(photoData.photoData);
                    break;
                case "activities":
                    state.activities.photos.push(photoData.photoData);
                    break;
                case "shopping":
                    state.shopping.photos.push(photoData.photoData);
                    break;
            }
        },
        updateCovidState: function (state, data) {
            state.covid.html = data.html;
            state.covid.raw_text = data.raw_text;
        },
        updateActivityStatus: function (state, statusData) {
            state.activities.selected_activities.forEach(function (activity) {
                if (activity.id === statusData.id) {
                    activity.status = statusData.status
                }
            })
        }
    },
    actions: {
    },
    getters: {
    }
}