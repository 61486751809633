/** expects messages to be either
 *      * A js object (added from front-end code) with params level, title and content (level can be info, success, warning or error) or
 *      * A js object injected by Django (added to Django messages framework and injected in _basetemplate.html),
 *        expected params are tags and message and need additional work to get into suitable format
 *  IzeleMessage object does some limited checking.
 * */
import { addToSentry } from "../../js/tools/general";


function IzeleMessage (messageObject, isTextInjectedMessage) {
    var level = "";

    if (isTextInjectedMessage) {
        if (!messageObject.hasOwnProperty("tags") || !messageObject.hasOwnProperty("message")) {
            throw ReferenceError("IzeleMessage: text injected message missing params");
        }

        // Django tags object is space separated string
        level = messageObject.tags.replace("izele_message ", "");

        var tempObject = JSON.parse(messageObject.message);
        if (!tempObject.hasOwnProperty("title") || !tempObject.hasOwnProperty("content")) {
            throw ReferenceError("IzeleMessage: text injected message body missing params")
        }

        this.title = tempObject.title;
        this.content = tempObject.content;

    } else {
        if (!messageObject.hasOwnProperty("level") || !messageObject.hasOwnProperty("title") || !messageObject.hasOwnProperty("content")) {
            throw ReferenceError("IzeleMessage: message object missing params");
        }

        level = messageObject.level;
        this.title = messageObject.title;
        this.content = messageObject.content;
    }

    if (level !== "info" && level !== "success" && level !== "warning" && level !== "error") {
        throw ReferenceError("IzeleMessage: invalid message level ('" + level + "')");
    } else {
        this.level = level;
    }

}

export default {
    namespaced: true,
    state: {
        messages: []
    },
    mutations: {
        addMessage: function (state, messageObject) {
            try {
                state.messages.push(new IzeleMessage(messageObject, false));
            } catch (e) {
                addToSentry(e);
            }

        },
        addTextInjectedMessage: function (state, textInjectedMessage) {
            try {
                state.messages.push(new IzeleMessage(textInjectedMessage, true));
            } catch (e) {
                addToSentry(e);
            }
        },
        deleteFirstMessage: function (state) {
            state.messages.shift();
        }
    },
    actions: {
        addTemplateInjectedMessages: function (context, messages) {
            // from Django backend and injected into _basetemplate.html
            messages.forEach(function (message) {
                context.commit("addTextInjectedMessage", message)
            });
        }
    },
    getters: {
        getMostRecentMessage: function (state) {
            return state.messages[0];
        }
    }
}