var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search-result-element",
      class: _vm.recordSelected,
      on: { click: _vm.selectRecord },
    },
    [
      _c("img", { attrs: { src: _vm.image, alt: "" } }),
      _vm._v(" "),
      _c("div", { staticClass: "sre-container" }, [
        _c("div", [
          _c("i", { staticClass: "visible-xs" }, [
            _vm._v(_vm._s(_vm.singleCountry)),
          ]),
          _vm._v(" "),
          _c("i", { staticClass: "hidden-xs" }, [
            _vm._v(_vm._s(_vm.multipleCountries)),
          ]),
          _vm._v(" "),
          _vm.result.countries && _vm.result.geoname_location
            ? _c("i", [_vm._v(", ")])
            : _vm._e(),
          _vm._v(" "),
          _vm.result.geoname_location
            ? _c("i", [_vm._v(_vm._s(_vm.result.geoname_location))])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "sre-type" }, [
            _vm._v(_vm._s(_vm.resultType)),
          ]),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }